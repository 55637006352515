import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import conexiones from '../urls/conexiones.json'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default class Cotizador extends Component {

    constructor() {
        super();
        this.onChangeValuesNecesitaDinero = this.onChangeValuesNecesitaDinero.bind(this)
        this.onChangeValuesPlazos = this.onChangeValuesPlazos.bind(this)
        this.onCotizar = this.onCotizar.bind(this)
        this.state = {
            reapaerecerBarraDinero: true,
            inicial:0, //3000,
            PrettoSlider: styled(Slider)({
                color: '#57D02D',
                height: 8,
                '& .MuiSlider-track': {
                    border: 'none',
                },
                '& .MuiSlider-thumb': {
                    height: 24,
                    width: 24,
                    backgroundColor: '#fff',
                    border: '2px solid currentColor',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'inherit',
                    },
                    '&::before': {
                        display: 'none',
                    },
                },
                '& .MuiSlider-valueLabel': {
                    lineHeight: 1.2,
                    fontSize: 12,
                    background: 'unset',
                    padding: 0,
                    width: 32,
                    height: 32,
                    borderRadius: '50% 50% 50% 0',
                    backgroundColor: '#57D02D',
                    transformOrigin: 'bottom left',
                    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                    '&::before': { display: 'none' },
                    '&.MuiSlider-valueLabelOpen': {
                        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                    },
                    '& > *': {
                        transform: 'rotate(45deg)',
                    },
                },
            }),
            valorPago: "",
            marcasPlazo: [],
            aumentosPlazo: 0,
            plazos: [],
            marks: [
                {
                    value: 1,
                    label: '$3,000',

                },

                {
                    value: 100,
                    label: '$300,000',

                },

            ],
            componenteActivo: 'solicitud',
            validaciones: {
                validaSector: false,
                validaProducto: false,
                validaTipoProducto: false,

            },
            nuevaSolicitud: {
                tipo_calculo: 0,
                producto: null,// 1,
                tipo_producto: null,//2,
                plazo: null,//123,
                frecuencia: null,//2,
                capacidad_pago: 0,//0,
                monto_solicitado: null,//50000
            }
        }
    }
    onChangeValues = e => {
        let validaciones = this.state.validaciones
        let nuevaSolicitud = this.state.nuevaSolicitud
        if (e.target.name == "selectSector") {
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                validaciones.validaSector = true
                nuevaSolicitud["producto"] = null
                this.setState({ validaciones: validaciones, selectProducto: [], producto_id_en_zell: "", banderaSector: false })
            } else {
                validaciones.validaSector = false
                nuevaSolicitud["producto"] = null
                this.setState({ selectProducto: [], producto_id_en_zell: "", nuevaSolicitud: nuevaSolicitud })
                this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/" + e.target.value, "selectProducto")

                this.setState({ validaciones: validaciones, banderaSector: true })
            }
            nuevaSolicitud["producto"] = parseInt(e.target.value)
        }

        if (e.target.name == "selectProducto") {
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                validaciones.validaProducto = true
                this.setState({ validaciones: validaciones, banderaConvenio: false })
            } else {
                validaciones.validaProducto = false
                this.setState({ validaciones: validaciones, banderaConvenio: true })
            }
            let res = e.target.value
            if (res.split(":").length > 0) {
                nuevaSolicitud["producto"] = res.split(":")[0]
                if (nuevaSolicitud["tipo_producto"] != 0 && nuevaSolicitud["tipo_producto"] != null
                    && nuevaSolicitud["tipo_producto"] != "Seleccione..." && nuevaSolicitud["tipo_producto"] != undefined) {
                    this.getPlazo(nuevaSolicitud["producto"], nuevaSolicitud["tipo_producto"])
                }
                this.getFrecuencia(nuevaSolicitud["producto"])
                //this.validaBanderaPortales(nuevaSolicitud["producto"])
                // this.setState({ producto_id_en_zell: res.split(":")[1] })
            }

        }

        if (e.target.name == "selectTipoProducto") {
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                validaciones.validaTipoProducto = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaTipoProducto = false
                this.setState({ validaciones: validaciones })
                if (nuevaSolicitud["producto"] != 0 && nuevaSolicitud["producto"] != null
                    && nuevaSolicitud["producto"] != "Seleccione..." && nuevaSolicitud["producto"] != undefined) {
                    this.getPlazo(nuevaSolicitud["producto"], e.target.value)
                }
            }

            nuevaSolicitud["tipo_producto"] = e.target.value
        }


        this.setState({ nuevaSolicitud: nuevaSolicitud })

    }
    async getCatalogo(url, selector) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const json = await response.json();
                console.log(json)
                this.armaSelect(json.filas, selector)
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    armaSelect(filas, selector) {
        let select = []
        let options = []
        options.push(<option value={null}>Seleccione...</option>)


        var contadorFilas = 0
        for (var i = 0; i < filas.length; i++) {
            let fila = filas[i].fila

            if (selector == "selectSector") {

                options.push(<option value={fila[0].value}>{fila[1].value}</option>)

            }

            if (selector == "selectProducto") {
                options.push(<option value={fila[0].value + ":" + fila[8].value}>{fila[1].value}</option>)
            }



            if (selector == "selectTipoProducto") {

                options.push(<option value={fila[0].value}>{fila[1].value}</option>)

            }
        }




        if (selector == "selectSector") {
            select.push(<Form.Select onChange={this.onChangeValues} name={selector} disabled={this.state.banderaSolicitudProcesada}  >{options}</Form.Select>)
        }

        if (selector == "selectProducto") {
            select.push(<Form.Select onChange={this.onChangeValues} name={selector} disabled={this.state.banderaSolicitudProcesada} >{options}</Form.Select>)
        }


        if (selector == "selectTipoProducto") {
            select.push(<Form.Select onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
        }


        this.setState({ [selector]: select })


    }
    async getFrecuencia(producto) {
        const response = await fetch(conexiones.urlPytonParametros + "catalogo_periodo/" + producto);
        if (response.ok) {
            const json = await response.json();
            console.log("frecuencia  ", json)
            if (json.filas.length > 0) {
                let nuevaSolicitud = this.state.nuevaSolicitud
                nuevaSolicitud["frecuencia"] = json.filas[0].fila[0].value
                this.setState({ nuevaSolicitud: nuevaSolicitud })
            }
        }
    }

    async getPlazo(producto, idTipoProducto) {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "producto": producto,
                "tipo_producto": idTipoProducto
            })
        };
        try {
            const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_producto_plazo/0", settings);
            const data = await fetchResponse.json();
            console.log("respesta de los plazos  ", data)
            this.setState({ plazos: data })
            let aumentos = 100 / (data.length - 1)
            this.setState({ aumentosPlazo: aumentos })
            let marcasPlazo = []
            if (data.length > 0) {
                let inicio = 0;
                for (var i = data.length -1; i >= 0; i--) {
                    if(i == data.length - 1){
                        let nuevaSolicitud=this.state.nuevaSolicitud
                        nuevaSolicitud.plazo=data[i].id
                    }
                    marcasPlazo.push({
                        value: inicio,
                        label: data[i].plazo,
                    })
                    inicio = inicio + aumentos
                }
                console.log("marcasPlazo ",marcasPlazo)
                marcasPlazo[marcasPlazo.length-1].value=100
                this.setState({ marcasPlazo: marcasPlazo })
            }
            //  this.armaSelectPlazos(data)


        } catch (e) {
            return e;
        }
    }
    UNSAFE_componentWillMount() {

        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sector/0", "selectSector")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_producto/0", "selectTipoProducto")

    }

    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }
    onChangeValuesNecesitaDinero = e => {
        let inicial = 3000
        console.log("onChangeValuesNecesitaDinero  ", e.target.value)
        let nuevaSolicitud = this.state.nuevaSolicitud
        nuevaSolicitud["monto_solicitado"] = e.target.value//inicial+(1000*e.target.value)
        this.setState({ nuevaSolicitud: nuevaSolicitud })
        this.setState({ inicial: e.target.value/*inicial+(1000*e.target.value)*/ })

    }

    onChangeValuesPlazos = e => {
        console.log("onChangeValuesPlazos  ", e.target.value, parseInt(e.target.value / this.state.aumentosPlazo))
        let data = this.state.plazos
        let posicion = e.target.value == 0 ? 0 : parseInt(e.target.value / this.state.aumentosPlazo)
        let cont = 0
        let sal = 0
        for (var i = data.length - 1; i >= 0; i--) {
            if (cont == posicion) {
                console.log("encontrado el id del plazo ", data[i])
                sal = data[i]
                break;
            }
            cont++;
        }

        let nuevaSolicitud = this.state.nuevaSolicitud
        nuevaSolicitud["plazo"] = sal.id
        this.setState({ nuevaSolicitud: nuevaSolicitud })
    }


    async onCotizar() {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.nuevaSolicitud)
        }
        const fetchResponse = await fetch(conexiones.ulrPortales + "wedo_capacidad_pago/0", settings);
        const data = await fetchResponse.json();
        console.log("respesta de los consumos  ", data[0])
        if (data.length > 0) {
            this.setState({ valorPago: data[0].descuento })
        }
    }



    render() {
        return (
            <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
                <div className="content-wrapper">
                    <div className="content-inner" style={{ margin: '10px' }}>

                        <Row>
                            <Col xs={4} md={4} lg={4}>
                                <br />
                                <Row>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Sector: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        {this.state.selectSector}
                                        {this.state.validaciones.validaSector == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un Sector</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <br />

                                <Row>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Convenio: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        {this.state.selectProducto}
                                        {this.state.validaciones.validaProducto == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un Convenio</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <br />
                                <Row>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Tipo de crédito: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        {this.state.selectTipoProducto}
                                        {this.state.validaciones.validaTipoProducto == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un Tipo de crédito:</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Row>


                            </Col>
                            <Col xs={8} md={8} lg={8}>
                                <Row>
                                    <Col xs={2} md={2} lg={2}>&nbsp;</Col>
                                    <Col xs={8} md={8} lg={8}>
                                        <br />
                                        <Row>

                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>{"Cuanto Necesitas ? : " + "$" + new Intl.NumberFormat('es-MX').format(this.state.inicial)} <span style={{ color: 'red' }}>*</span></Form.Label>
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6} lg={6}>
                                                <Form.Group className="mb-6" controlId="">
                                                    <Form.Control
                                                        type="number"
                                                        value={this.state.inicial}
                                                        onChange={(e) => {
                                                            console.log("on change ",e.target.value)
                                                            let nuevaSolicitud = this.state.nuevaSolicitud
                                                            nuevaSolicitud["monto_solicitado"] = e.target.value
                                                            nuevaSolicitud["monto_solicitado"] = parseInt(nuevaSolicitud["monto_solicitado"])
                                                            this.setState({ nuevaSolicitud: nuevaSolicitud , inicial:e.target.value})
                                                           /* setTimeout(() => {
                                                                let inicial = this.state.inicial
                                                                inicial = e.target.value
                                                                this.setState({ inicial: inicial })
                                                            }, 1500)*/
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {/*<Form.Group className="mb-6" controlId="">
                                                <Form.Label>{"$" + new Intl.NumberFormat('es-MX').format(this.state.inicial)}</Form.Label>
                                            </Form.Group>*/}
                                        </Row>
                                        {
                                            /* this.state.reapaerecerBarraDinero ?
                                                 <Row >
 
                                                     <this.state.PrettoSlider
 
                                                         aria-label="pretto slider"
                                                         defaultValue={1}
                                                         step={500}
                                                         marks
                                                         min={3000}
                                                         max={300000}
                                                         onChange={this.onChangeValuesNecesitaDinero}
                                                     />
 
                                                 </Row> : ""*/
                                            <Slider
                                                value={this.state.inicial}
                                                onChange={(e)=>{
                                                    let nuevaSolicitud = this.state.nuevaSolicitud
                                                    nuevaSolicitud["monto_solicitado"] = e.target.value//inicial+(1000*e.target.value)
                                                    this.setState({ nuevaSolicitud: nuevaSolicitud })
                                                    this.setState({inicial:e.target.value})
                                                }}
                                                aria-labelledby="input-slider"
                                                min={3000}
                                                max={300000}
                                                defaultValue={1}
                                                step={500}
                                                color={"success"}
 
                                            />

                                        }
                                        {/*
                                        <Row  style={{display:"flex"}}>
                                            <Col xs={1} md={1} lg={1}>
                                            <AddCircleIcon style={{backgroundColor:"#57D02D"}}/>    
                                            </Col>
                                            <Col xs={8} md={10} lg={10}>
                                            &nbsp;
                                            </Col>

                                            <Col xs={1} md={1} lg={1}>
                                            <AddCircleIcon style={{backgroundColor:"#57D02D"}}/>    
                                            </Col>                                            
                                        </Row>
                                    */}

                                        <br />
                                        <Row>
                                            <Form.Group className="mb-6" controlId="">
                                                <Form.Label>En cuantos pagos ? <span style={{ color: 'red' }}>*</span></Form.Label>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Slider
                                                onChange={this.onChangeValuesPlazos}
                                                aria-label="pretto slider"
                                                defaultValue={0}
                                                marks={this.state.marcasPlazo}
                                                step={this.state.aumentosPlazo}
                                                color={"success"}
                                            />
                                        </Row>



                                        <br />
                                        <Row>
                                            <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                                            <Col xs={12} md={4} lg={4}>
                                                <Form.Group className="mb-6" controlId="">
                                                    <Form.Label>Tu pago quincenal será de: </Form.Label>
                                                    <Form.Control type="text" placeholder="" value={this.state.valorPago} />
                                                </Form.Group>
                                                <br />
                                                <Button variant="secondary" style={{ width: "100%", backgroundColor: '#FF6B00', borderColor: "#FF6B00" }} onClick={this.onCotizar}>
                                                    Cotizar
                                                </Button>

                                            </Col>
                                            <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                                        </Row>



                                    </Col>

                                    <Col xs={2} md={2} lg={2}>&nbsp;</Col>

                                </Row>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}