import React, { useRef ,useEffect } from 'react';
import RecordRTC from 'recordrtc';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import conexiones from '../urls/conexiones.json'
import Button from 'react-bootstrap/Button';

const VideoIMSSios = (props) => {
    const videoRef = useRef(null);
    const recordingRef =useRef(false);
    let recordRTC;
    const [nombreSolicitante,setNombreSolicitante]=React.useState("");
    const [montoSolicitado,setMontoSolicitado]=React.useState("");
    const [idDXN,setIdDxn]=React.useState("");
    const [plazo,setPlazo]=React.useState(0);
    const [frecuencia,setFrecuencia]=React.useState(""); 
    const [iniciaGrabacion,setIniciaGrabacion]=React.useState(false);
    const [iniciaGrabacion2,setIniciaGrabacion2]=React.useState("visible");



    async function getSolicitud(idSolicitud) {
      try {
          const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
          if (response.ok) {
              const json = await response.json();
              console.log("respuesta de la solicitud ", json[0])
              setIdDxn(json[0].appid)
              
          } else {
              console.error('Error:', response.status);
             // this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getSolicitud  respuesta 500", showModal: true })
          }
      } catch (error) {
          //this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getSolicitud servicio interrumpido", showModal: true })
      }
  }

    useEffect(() => {
        console.log("entrando al componente ", props)
    
    
        if (props.idSolicitud != null) {
          getSolicitud(props.idSolicitud)
          if (props.existenDatosPersonales && props.existenDatosDomicilio && 
            props.existenDatosContacto &&
            props.existenDatosBancarios &&
            props.existenDatosOcupacion && props.existenDatosReferencias) {
                const stopButton = document.getElementById('stopButton');

                stopButton.style.display = 'none';
                startCamera()

              let primerNombre=
               props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ? 
               props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].primer_nombre+" ": props.datosPersonalesServicio.primer_nombre +" ":""
              let segundoNombre=
               props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].segundo_nombre+" ": props.datosPersonalesServicio.segundo_nombre:"" 
              let primerApp=
               props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].primer_apellido+" ": props.datosPersonalesServicio.primer_apellido +" ":""
             let segundoApp=
               props.datosPersonalesServicio != null &&  props.datosPersonalesServicio != undefined ?  props.datosPersonalesServicio instanceof Array ?  props.datosPersonalesServicio[0].segundo_apellido+" ": props.datosPersonalesServicio.segundo_apellido:" "
               setNombreSolicitante( primerNombre + segundoNombre + primerApp + segundoApp)
               setMontoSolicitado("$ "+props.nuevaSolicitudServicio.monto_solicitado)
               getPlazo()
               getFrecuencia()
          }
        }
      }, [])

      async function  getPlazo () {
        const settings = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "producto": props.nuevaSolicitudServicio.producto_id,
            "tipo_producto": props.nuevaSolicitudServicio.tipo_producto_id
          })
        };
        try {
          const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_producto_plazo/0", settings);
          const data = await fetchResponse.json();
          console.log("respesta de los plazos  ", data)
          if(data.length > 0){
            for(var i=0; i<data.length; i++){
              if(data[i].id == props.nuevaSolicitudServicio.plazo_id){
                setPlazo(data[i].plazo)
                break;
              }
            }  
          }
        } catch (e) {
          return e;
        }
      }
    
      async function  getFrecuencia () {
        const settings = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        };
        try {
          const fetchResponse = await fetch(conexiones.urlPytonParametros + "catalogo_periodo/"+props.nuevaSolicitudServicio.producto_id, settings);
          const data = await fetchResponse.json();
          if(data.filas.length > 0){
            for(var i=0; i<data.filas.length; i++){
              let fila =data.filas[i].fila
              console.log("respesta de la frecuencia   ", fila[0])
              if(fila[0].value ==props.nuevaSolicitudServicio.periodo_id){
                setFrecuencia(fila[1].value)
                break
              }
    
            }
          }
    
    
        } catch (e) {
          return e;
        }
      }
    

      const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            videoRef.current.srcObject = stream;
            recordRTC = new RecordRTC(stream, {
                type: 'video',
                mimeType: 'video/mp4', // Puedes ajustar este mimeType según necesidad y compatibilidad
                recorderType: RecordRTC.MediaStreamRecorder,
            });
            //recordRTC.startRecording();
        } catch (error) {
            alert("Error accessing media devices.", error);
        }
    };






    const startRecording = async () => {
        
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            videoRef.current.srcObject = stream;
            recordRTC = new RecordRTC(stream, {
                type: 'video',
                mimeType: 'video/mp4', // Puedes ajustar este mimeType según necesidad y compatibilidad
                recorderType: RecordRTC.MediaStreamRecorder,
            });
            recordRTC.startRecording(); 
            recordingRef.current = true;
            updateButtons(); // Actualiza la visibilidad de los botones
        } catch (error) {
            alert("Error accessing media devices.", error);
            console.log("error  ",error)
        }
    };

    const stopRecording =  () => {
        recordRTC.stopRecording(() => {
            const blob = recordRTC.getBlob();
            downloadVideo(blob);
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
            recordingRef.current = false;
            updateButtons(); // Actualiza la visibilidad de los botones
            //startCamera()
        });
    };

    const downloadVideo = (blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'recorded-video.mp4';
        a.click();
        window.URL.revokeObjectURL(url);
    };


    const updateButtons = () => {
        const startButton = document.getElementById('startButton');
        const stopButton = document.getElementById('stopButton');
        if (recordingRef.current) {
            startButton.style.display = 'none';
            stopButton.style.display = 'block';
        } else {
            startButton.style.display = 'block';
            stopButton.style.display = 'none';
        }
    };

    return (
        <>
          < Row>
                    <Col xs={12}>
                        <span style={{ font: "bold", color: "#FF4000" }}>Sol: {props.idSolicitud == null ? "0" : props.idSolicitud}  DXN:{idDXN} </span>

                    </Col>
                </Row>
            <Row>
                <Col xs={0} md={2} lg={2}>&nbsp;</Col>
                <Col xs={12} md={8} lg={8}>
                    <span style={{ fontSize: "15px", fontWeight: "bold", color: "red" }}>{"Una vez que estés listo presiona en comenzar para iniciar la grabación, debes leer la leyenda a la derecha recuerda que en todo momento debe estar enfocado tu rostro​"}</span>
                </Col>
                <Col xs={0} md={2} lg={2}>&nbsp;</Col>

            </Row >
            <br />

            <Row>
                <Col xs={12} md={6} lg={6}>

                    <div>
                        <video  style={{ width: "100%" }}ref={videoRef} autoPlay playsInline></video>
                        {
                            
                        <Button id="startButton" style={{  backgroundColor:"#FF6B00", borderColor:"#FF6B00", color:"white",width: '100%', height: "60px", borderRadius: '40px',  cursor: "pointer" }} 
                        onClick={startRecording}>Iniciar grabación</Button>
                        }

<br />
                        {
                            
                        <Button onClick={stopRecording} id="stopButton"
                         style={{  backgroundColor:"red", borderColor:"red", color:"white",width: '100%', height: "60px", borderRadius: '40px', cursor: "pointer" }}>Detener grabación</Button>
                        }
                    </div>

                </Col>

                <Col xs={12} md={6} lg={6}>
                    <span style={{ fontSize: "25px", fontWeight: "bold" }}>{"Yo " + nombreSolicitante +
                        ",acepto de DXN EXPRESS SA DE CV SOFOM ENR el otorgamiento de un crédito por un monto de " + montoSolicitado + ",mismo que, debe ser retenido en " + plazo + "  " + frecuencia + " Asimismo, autorizo al IMSS a que retenga de mi <Tipo de pensión>, el importe correspondiente para dar cumplimiento a las obligaciones pactadas en el contrato de crédito y me comprometo a dejar en paz y a salvo al IMSS de cualquier controversia que pudiera derivarse del otorgamiento del crédito."}</span>

                </Col>

            </Row >




        </>
    );
};

export default VideoIMSSios;