import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import conexiones from '../urls/conexiones.json'
import capSol1 from "../imagenes/1.Captura.png"
import analizada2 from "../imagenes/2.Analisis.png"
import solDev3 from "../imagenes/3.continua analisis.png"
import yarechazada4 from "../imagenes/4.firma.png"
import solAutorizada5 from "../imagenes/5.Autorizada.png"
import clienteDinero6 from "../imagenes/6.Depositado.png"
import comisiones7 from "../imagenes/7.Comisiones.png"
import devuelta8 from "../imagenes/8.Devuelta.png"
import denegada9 from "../imagenes/9.Denegada.png"
import vencida10 from "../imagenes/vencidas9.png"
import UpdateIcon from '@mui/icons-material/Update';
import BackupTableIcon from '@mui/icons-material/BackupTable';



import circulosVerdes1 from "../imagenes/1C. Mi solicitud captura.png"
import circulosVerdes2 from "../imagenes/2C. Analizada.png"
import circulosVerdes3 from "../imagenes/3C. Ops has sido devuelta.png"
import circulosVerdes4 from "../imagenes/4C.ha sido denegada.png"
import circulosVerdes5 from "../imagenes/5C.Autorizada.png"
import circulosVerdes6 from "../imagenes/6C. Mi cliente ya tiene el dinero.png"
import circulosVerdes7 from "../imagenes/7C. Comision pagada.png"



//import circulosVerdes30 from "../imagenes/cirulos verdes 30%.png"


import Modal from 'react-bootstrap/Modal';
import Table2 from 'react-bootstrap/Table';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
//import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
//import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import CreateOutlinedIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from 'react-bootstrap/Button';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Pagination from 'react-bootstrap/Pagination';
import "react-datepicker/dist/react-datepicker.css";
import CreaNuevaSolicitud from "./CreaNuevaSolicitud";
import DatePicker from "react-datepicker";
/* configuracion para tabla con ejes dobles */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
//import Pagination from '@mui/material/Pagination';/
//import Stack from '@mui/material/Stack';
//import Card from 'react-bootstrap/Card';
//import Grid from '@mui/material/Grid';
import Card from 'react-bootstrap/Card';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';



export default class ConsultaSolicitudes extends Component {

    constructor() {
        super();
        this.getCatalogo = this.getCatalogo.bind(this)
        this.getDataTable = this.getDataTable.bind(this)
        this.armaTabla = this.armaTabla.bind(this)
        this.armaPaginador = this.armaPaginador.bind(this)
        this.cambiaPagina = this.cambiaPagina.bind(this)
        this.convert = this.convert.bind(this)
        this.consultar = this.consultar.bind(this)
        this.seleccionaSolicitud = this.seleccionaSolicitud.bind(this)
        this.regresarABandeja = this.regresarABandeja.bind(this)
        this.createData = this.createData.bind(this)
        this.onChangeBusquedaTabla = this.onChangeBusquedaTabla.bind(this)
        this.state = {
            valorBusquedaFolio: "",
            placeHolderFolio: "",
            valorTipoFolio: 2,
            valorBusquedaTabla: "",
            idSolResp: null,
            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorInformativo: "#FF6B00",
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            appIDSeleccionado: null,

            idSolicitud: null,
            banderaSolicitud: false,
            banderaDocumentos: false,
            colorEntrando: "blue",
            valoresTabla: null,
            selectEstatus: [],
            selectTR: [],
            selectEstatusBusqueda: [],
            paginacion: null,
            topePaginacion: 20,
            fechaInicio: null,
            fechaFin: Date.now(),
            idEstatus: 1,
            idEstatusCambio: null,
            zellEstatus: [],
            idEstatusSolicitudes1: 0,
            idEstatusSolicitudes2: 0,
            idEstatusSolicitudes3: 0,
            idEstatusSolicitudes4: 0,
            idEstatusSolicitudes5: 0,
            idEstatusSolicitudes6: 0,
            idEstatusSolicitudes7: 0,
            idEstatusSolicitudes8: 0,
            idEstatusSolicitudes9: 0,
            idEstatusSolicitudes10: 0,
            idEstatusBusqueda: 0,
            page: 0,
            rowsPerPage: 1,

            columns: [
                { id: 'Estatus de mi venta', label: 'Estatus de mi venta', minWidth: 170 },
                { id: 'Solicitud', label: 'Solicitud', minWidth: 100 },
                {
                    id: 'Sucursal',
                    label: 'Sucursal',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toLocaleString('en-US'),
                },
                {
                    id: 'Cliente',
                    label: 'Cliente',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toLocaleString('en-US'),
                },
                {
                    id: 'Datos del crédito',
                    label: 'Datos del crédito',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toFixed(2),
                },
                {
                    id: 'Datos del crédito	',
                    label: 'Opciones',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toFixed(2),
                },



            ],

        }

    }



    llamarAContabilidad() {
        let json = {
            "tipo_usuario": this.props.isSuperUsuario == true ? 1 : 2,
            "fecha_inicio": this.convert(this.state.fechaInicio),
            "fecha_fin": this.convert(this.state.fechaFin),
            "user_id": this.props.idUsuario
        }


        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        fetch(conexiones.urlListado2, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log("contabilidad ", data)
                if (data.solicitudes.length > 0) {
                    let solicitudes = data.solicitudes;
                    for (var i = 0; i < solicitudes.length; i++) {
                        console.log("idEstatusSolicitudes" + (i + 1))
                        if (solicitudes[i].estatus_id == 1) {
                            this.setState({ ["idEstatusSolicitudes" + (1)]: solicitudes[i].total })
                        }

                        if (solicitudes[i].estatus_id == 2) {
                            this.setState({ ["idEstatusSolicitudes" + (2)]: solicitudes[i].total })

                        } if (solicitudes[i].estatus_id == 3) {
                            this.setState({ ["idEstatusSolicitudes" + (3)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 4) {
                            this.setState({ ["idEstatusSolicitudes" + (4)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 5) {
                            this.setState({ ["idEstatusSolicitudes" + (5)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 6) {
                            this.setState({ ["idEstatusSolicitudes" + (6)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 7) {
                            this.setState({ ["idEstatusSolicitudes" + (7)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 8) {
                            this.setState({ ["idEstatusSolicitudes" + (8)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 9) {
                            this.setState({ ["idEstatusSolicitudes" + (9)]: solicitudes[i].total })
                        }
                        if (solicitudes[i].estatus_id == 10) {
                            this.setState({ ["idEstatusSolicitudes" + (10)]: solicitudes[i].total })
                        }






                    }
                }
            }).catch(error => {
                console.error("error en la contabilidad de solicitudes ")
            })
    }

    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("/");
    }
    UNSAFE_componentWillMount() {
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vstatus/" + this.props.idUsuario, "selectEstatus")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_estatus/0", "selectEstatusBusqueda")

        let cincoDias = 1000 * 60 * 60 * 24 * 5;
        let hoy = new Date();
        let resta = hoy.getTime() - cincoDias;
        let fechaDeHaceCincoDias = new Date(resta);
        this.setState({ fechaFin: hoy, fechaInicio: fechaDeHaceCincoDias })
        let json = {
            "estatus": 1,
            "fecha_inicio": this.convert(fechaDeHaceCincoDias),
            "fecha_fin": this.convert(hoy),
            "user_id": this.props.idUsuario
        }

        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        fetch(conexiones.urlListado, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ valoresTabla: data })
                this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)
                this.llamarAContabilidad()
            }).catch(error => {

            })
    }

    onChangeValues = e => {

        if (e.target.name == "selectEstatus") {
            this.setState({ idEstatusCambio: e.target.value })
        }
        if (e.target.name == "selectEstatusBusqueda") {
            this.setState({ idEstatusBusqueda: e.target.value })
        }
    }

    async getDataTable(url) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const json = await response.json();
                console.log(json)
                this.setState({ valoresTabla: json })
                // this.armaTabla(json, 1, this.state.topePaginacion + 1)
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }
    armaTabla(data, inicio, fin, pagina) {
        let selectTR = []
        console.log(data)

        inicio = inicio - 1
        fin = fin - 1
        console.log(inicio)
        console.log(fin)
        for (var i = inicio; i < fin; i++) {
            if (data[i] != undefined) {
                console.log("/////////////////////////////// ", data[i])
                let selectTD = []
                selectTD.push(
                    <TableCell align={"center"} >
                        {<Row><h6 >{data[i].estatus} </h6><h6 style={{ color: "#FF6B00" }}>{data[i].estatus_desc} </h6></Row>}
                        {
                            data[i].estatus_id == 1 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes1}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 2 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes2}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 3 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes3}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 4 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes4}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 5 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes5}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 6 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes6}></Card.Img> : ""
                        }
                        {
                            data[i].estatus_id == 7 ? <Card.Img style={{ width: "100%", height: "%" }} align={"center"} src={circulosVerdes7}></Card.Img> : ""
                        }





                        {/*<Card.Img  style={{ width: "100%", height: "%"  }}  align={"center"} src={circulosVerdes30}></Card.Img>*/}
                        {/*<RadioButtonCheckedOutlinedIcon style={{ color: '#01DF01' }} /> &nbsp; &nbsp; {data[i].estatus}*/}
                    </TableCell>
                )
                selectTD.push(<TableCell align={"left"}>
                    <>
                        <span style={{ color: 'blue', fontWeight: 'bold' }}>{data[i].tipo_producto} </span>
                        <br></br>
                        <WorkOutlinedIcon style={{ color: '#585858' }} />{data[i].producto}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>{data[i].folio}</span>
                        <br></br>
                        <CalendarMonthOutlinedIcon style={{ color: '#585858' }} />{data[i].fecha_solicitud}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>{data[i].appid}</span>

                    </>
                </TableCell>)
                selectTD.push(<TableCell align={"left"}>
                    <>
                        <AppShortcutOutlinedIcon />{data[i].sucursal}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>  Asesor: </span>{data[i].asesor}
                        <br></br>
                        <><span style={{ fontWeight: 'bold', color: 'green' }}>  Comisión: </span>   <span style={{ color: 'green' }} >{"$" + this.mascaraDecimales(data[i].importe_comision)}</span></>
                    </>
                </TableCell>)

                selectTD.push(<TableCell align={"left"}>
                    <>
                        <PersonOutlineOutlinedIcon style={{ color: '#585858' }} /> {data[i].cliente == null ? '' : data[i].cliente}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>RFC: </span>{data[i].rfc}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>CURP: </span>{data[i].curp}

                    </>
                </TableCell>)
                selectTD.push(<TableCell align={"left"}>
                    <>
                        <span style={{ fontWeight: 'bold' }}>Monto: $</span>{data[i].monto_solicitado}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Plazo: </span>{data[i].plazo}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Desc: $</span>{data[i].descuento}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Tasa: </span>{data[i].tasa_anual}<span>%</span>

                    </>
                </TableCell>)

                selectTD.push(<TableCell align={"left"}>
                    <>
                        <Row>
                            <Col lg={3} md={3} xs={3}>
                                <CreateOutlinedIcon onClick={this.seleccionaSolicitud(data[i].id, data[i].flag_solicitud, data[i].flag_documento)} style={{ color: '#ffd700', cursor: 'pointer' }} />
                            </Col>

                            <Col lg={3} md={3} xs={3}>
                                <VisibilityIcon onClick={this.seleccionaSolicitudOjo(data[i].id, data[i].flag_solicitud, data[i].flag_documento)} style={{ color: '#77dd77', cursor: 'pointer' }} ></VisibilityIcon>
                            </Col>

                            <Col lg={3} md={3} xs={3}>
                                <CachedIcon onClick={

                                    /*  data[i].appid != "DXN-0" ?*/
                                    this.seleccionaSolicitudActualiza(data[i].id, data[i].flag_solicitud, data[i].flag_documento, data[i].folio, data[i].appid)
                                    /*:console.log("p")*/

                                } style={{ color: '#84b6f4', cursor: 'pointer' }} />
                            </Col>

                            <Col lg={3} md={3} xs={3}>
                                <ContentCopyIcon onClick={this.seleccionaSolicitud(data[i].id, data[i].flag_solicitud, data[i].flag_documento)} style={{ color: '#c5c6c8', cursor: 'pointer' }} />
                            </Col>
                        </Row>
                    </>
                </TableCell>)


                selectTR.push(<TableRow>{selectTD}</TableRow>)


            }


        }
        this.setState({ selectTR: selectTR })
        this.armaPaginador(data, pagina)



    }

    cambiaPagina(numPagina, e) {
        return event => {
            let conteo = (numPagina) * this.state.topePaginacion
            let inicio = (conteo - this.state.topePaginacion) + 1
            let fin = conteo + 1
            this.armaTabla(this.state.valoresTabla, inicio, fin, numPagina)
        }

    }

    armaPaginador(data, pagina) {
        let items = []
        let paginas = data.length / this.state.topePaginacion;

        if (paginas % 2 == 0) {
            console.log("total de paginas  == 0 " + paginas)
            for (var i = 1; i <= paginas; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        } else {
            let paginasString = paginas + ""
            let paginaInt = parseInt(paginasString.split(".")[0]) + 1
            console.log("total de paginas != 0 " + (paginaInt))

            for (var i = 1; i <= paginaInt; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        }

        let paginacion = <Pagination>{items}</Pagination>
        this.setState({ paginacion: paginacion })




    }

    async getCatalogo(url, selector) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const json = await response.json();
                console.log(json)
                this.armaSelect(json.filas, selector)
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    armaSelect(filas, selector) {
        let select = []
        let options = []
        if(selector == "selectEstatus"){
            options.push(<option value={0}>Seleccione...</option>)
        }

        if(selector == "selectEstatusBusqueda"){
            options.push(<option value={0}>Estatus...</option>)
        }
 


        for (var i = 0; i < filas.length; i++) {
            let fila = filas[i].fila

            if (selector == "selectEstatus") {
                if (this.state.idEstatus == fila[0].value) {
                    options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
                } else {
                    options.push(<option value={fila[0].value}>{fila[1].value}</option>)
                }
            }

            if (selector == "selectEstatusBusqueda") {
            /*    if (this.state.idEstatusBusqueda == fila[0].value) {
                    options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
                } else {*/
                    options.push(<option value={fila[0].value}>{fila[1].value}</option>)
              //  }
            }


        }


        if (selector == "selectEstatus" || selector == "selectEstatusBusqueda") {
            select.push(<Form.Select onChange={this.onChangeValues} name={selector}   >{options}</Form.Select>)
        }



        this.setState({ [selector]: select })
    }

    consultar() {

        
        if(this.state.valorBusquedaFolio == "" && (this.state.idEstatusBusqueda == 0  || this.state.idEstatusBusqueda ==  "Estatus..." )){
            console.log("datos validando ",this.state.valorBusquedaFolio,this.state.idEstatusBusqueda)
        
        let json = {
            "estatus": this.state.idEstatus == "0" ? 0 : this.state.idEstatus,
            "fecha_inicio": this.convert(this.state.fechaInicio),
            "fecha_fin": this.convert(this.state.fechaFin),
            "user_id": this.props.idUsuario
        }

        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        fetch(conexiones.urlListado, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ valoresTabla: data })
                this.armaTabla(data, 1, this.state.topePaginacion + 1)
            }).catch(error => {

            })
        }
    }

    seleccionaSolicitudActualiza(id, banderaSolicitud, banderaDocumentos, solicitud, appID, e) {
        return event => {

            fetch(conexiones.urlServicioZell + "zell_bitacora/" + id)
                .then(response => response.json())
                .then(data => {
                    console.log(" data zeel bitacora ", data)
                    //    if (data.length > 0) {
                    let salidaEncabezados = []
                    let salida = ''

                    for (var i = 0; i < data.length; i++) {

                        salidaEncabezados.push(
                            <tr >
                                <td style={{ textAlign: 'left' }}>{data[i].desc_wedo}</td>
                                { /* <td style={{ textAlign: 'left' }}>{data[i].estatus_zell}</td>
                                <td style={{ textAlign: 'left' }}>{data[i].desc_zell}</td>*/}
                                <td style={{ textAlign: 'left' }}>{data[i].comentario_zell}</td>
                                <td style={{ textAlign: 'left' }}>{data[i].fecha}</td>

                            </tr>
                        )
                    }

                    salida =
                        <>
                            <h2 style={{ textAlign: 'left' }}>{solicitud}, Folio Zell :{appID}</h2>
                            <br />
                            <Row>
                                <Col lg={12} md={12} xs={12}>
                                    <Form.Group style={{ textAlign: 'left' }} className="mb-6" controlId="">
                                        <Form.Label >Estatus de solicitud : <span style={{ color: 'red' }}>*</span></Form.Label>
                                        {this.state.selectEstatus}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Table2 striped bordered hover>
                                <thead>
                                    <tr >
                                        <th style={{ textAlign: 'left' }}>Descrip WEDO</th>
                                        {/*   <th style={{ textAlign: 'left' }}>Estatus zell</th>
                                    <th style={{ textAlign: 'left' }}>Descrip zell</th>*/}
                                        <th style={{ textAlign: 'left' }}>Comentarios</th>
                                        <th style={{ textAlign: 'left' }}>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salidaEncabezados}
                                </tbody>
                            </Table2>
                        </>


                    this.setState({
                        idSolResp: id, appIDSeleccionado: appID,
                        colorModal: this.state.colorInformativo, banderaSolicitud: banderaSolicitud == 1 ? false : true, banderaDocumentos: banderaDocumentos == 1 ? false : true,
                        tituloModal: 'Cambio de estatus de solicitud !', mensajeError: salida, showModalActualizar: true
                    })
                    // } else {
                    //  this.setState({ idSolicitud: id })
                    // }
                }).catch(error => {

                })
        }
    }

    seleccionaSolicitud(id, banderaSolicitud, banderaDocumentos, e) {
        return event => {
            this.setState({ idSolicitud: id, banderaSolicitud: banderaSolicitud == 1 ? false : true, banderaDocumentos: banderaDocumentos == 1 ? false : true })
        }
    }
    seleccionaSolicitudOjo(id, banderaSolicitud, banderaDocumentos, e) {
        return event => {
            console.log("entando  ", id)
            fetch(conexiones.urlServicioZell + "zell_bitacora/" + id)
                .then(response => response.json())
                .then(data => {
                    console.log(" data zeel bitacora ", data)
                    if (data.length > 0) {
                        let salidaEncabezados = []
                        let salida = ''

                        for (var i = 0; i < data.length; i++) {

                            salidaEncabezados.push(
                                <tr >
                                    <td style={{ textAlign: 'left' }}>{data[i].desc_wedo}</td>
                                    { /* <td style={{ textAlign: 'left' }}>{data[i].estatus_zell}</td>
                                    <td style={{ textAlign: 'left' }}>{data[i].desc_zell}</td>*/}
                                    <td style={{ textAlign: 'left' }}>{data[i].comentario_zell}</td>
                                    <td style={{ textAlign: 'left' }}>{data[i].fecha}</td>

                                </tr>
                            )
                        }

                        salida =
                            <Table2 striped bordered hover>
                                <thead>
                                    <tr >
                                        <th style={{ textAlign: 'left' }}>Descrip WEDO</th>
                                        {/*   <th style={{ textAlign: 'left' }}>Estatus zell</th>
                                        <th style={{ textAlign: 'left' }}>Descrip zell</th>*/}
                                        <th style={{ textAlign: 'left' }}>Comentarios</th>
                                        <th style={{ textAlign: 'left' }}>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salidaEncabezados}
                                </tbody>
                            </Table2>



                        this.setState({
                            colorModal: this.state.colorInformativo,
                            banderaSolicitud: banderaSolicitud == 1 ? false : true,
                            banderaDocumentos: banderaDocumentos == 1 ? false : true, tituloModal: 'Consulta de Observaciones !', mensajeError: salida, showModal: true, idSolResp: id
                        })
                    } else {
                        this.setState({ idSolicitud: id })
                    }
                }).catch(error => {

                })



        }
    }

    regresarABandeja() {
        this.setState({ idSolicitud: null })
    }




    createData(name, code, population, size) {
        const density = population / size;
        return { name, code, population, size, density };
    }

    onChangeBusquedaTabla = e => {
        console.log(e.target.name, e.target.value)
        this.setState({ valorBusquedaTabla: e.target.value })
        let input = e.target.value
        if (input.length != 0) {
            console.log("valores tabla   ", this.state.valoresTabla)
            let valoresNuevaTabla = []
            for (var i = 0; i < this.state.valoresTabla.length; i++) {
                let camposFila = this.state.valoresTabla[i]
                let contenido = Object.values(camposFila)
                console.log("el contenido de cada fila  ", contenido)
                for (var j = 0; j < contenido.length; j++) {
                    if (contenido[j] != null) {
                        let valor = contenido[j]
                        valor = valor.toString()
                        valor = valor.toLowerCase()
                        let valorComparacion = e.target.value
                        valorComparacion = valorComparacion.toString()
                        valorComparacion = valorComparacion.toLowerCase()
                        if (valor.includes(valorComparacion)) {
                            valoresNuevaTabla.push(camposFila)
                            break;
                        }
                    }
                }
            }
            this.setState({ valoresTabla: valoresNuevaTabla })
            this.armaTabla(valoresNuevaTabla, 1, this.state.topePaginacion + 1, 1)
            console.log("la nueva tabla ", valoresNuevaTabla)
        } else {
            /* let cincoDias = 1000 * 60 * 60 * 24 * 5;
             let hoy = new Date();
             let resta = hoy.getTime() - cincoDias;
             let fechaDeHaceCincoDias = new Date(resta);
             this.setState({ fechaFin: hoy, fechaInicio: fechaDeHaceCincoDias })*/
            let json = {
                "estatus": 1,
                "fecha_inicio": this.convert(this.state.fechaInicio),
                "fecha_fin": this.convert(this.state.fechaFin),
                "user_id": this.props.idUsuario
            }

            const requestReferidoOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(json)
            }

            fetch(conexiones.urlListado, requestReferidoOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    this.setState({ valoresTabla: data })
                    this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)
                    this.llamarAContabilidad()
                }).catch(error => {

                })
        }


    }


    onChangeValuesSelectorFolio = e => {
        this.setState({ valorTipoFolio: e.target.value, placeHolderFolio: e.target.value == 1 ? "Folio WEDO" : "Folio ZELL", valorBusquedaFolio: "" })
    }


    llamarBusquedaFolioDirecto() {
        let idEstatusBusqueda=0
        if(this.state.idEstatusBusqueda != null && this.state.idEstatusBusqueda != "Estatus..."  ){
            idEstatusBusqueda=  parseInt( this.state.idEstatusBusqueda)
        }
        let json = {
            "estatus_id":idEstatusBusqueda ,
            "folio": this.state.valorBusquedaFolio,
            "user_id": this.props.idUsuario,
            "fecha_inicio": this.convert(this.state.fechaInicio),
            "fecha_fin": this.convert(this.state.fechaFin),

        }


        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        fetch(conexiones.urlBusquedaFolioDirecto, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ valoresTabla: data })
                this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)
                this.llamarAContabilidad()
            }).catch(error => {
                console.error("error en la folio directo ")
            })
    }

    actualizarEstatus(appID, estatusSeleccionado, idSolResp) {
        let json = {
            "ErrorCode": 0,
            "ErrorDescription": null,
            "AppId": appID,
            "Comment": "",
            "OpStatus": estatusSeleccionado,
            "solicitud": idSolResp
        }


        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }

        fetch(conexiones.urlServicioZell + "zell_actualiza_estatus/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log("respuesta de la actualizacion ", data)
                if (data.length > 0) {
                    if (data[0].ErrorCode == 0) {
                        this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Atención !', mensajeError: "El estatus de la solicitud se cambio de forma correcta !", showModal: true, })
                        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_vstatus/" + this.props.idUsuario, "selectEstatus")
                        let cincoDias = 1000 * 60 * 60 * 24 * 5;
                        let hoy = new Date();
                        let resta = hoy.getTime() - cincoDias;
                        let fechaDeHaceCincoDias = new Date(resta);
                        this.setState({ fechaFin: hoy, fechaInicio: fechaDeHaceCincoDias })
                        let json = {
                            "estatus": 1,
                            "fecha_inicio": this.convert(fechaDeHaceCincoDias),
                            "fecha_fin": this.convert(hoy),
                            "user_id": this.props.idUsuario
                        }

                        const requestReferidoOptions = {
                            method: "POST",
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(json)
                        }

                        fetch(conexiones.urlListado, requestReferidoOptions)
                            .then(response => response.json())
                            .then(data => {
                                console.log(data)
                                this.setState({ valoresTabla: data })
                                this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)
                                this.llamarAContabilidad()
                            }).catch(error => {

                            })
                    } else {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Atención !', mensajeError: "El estatus de la solicitud no  se cambio de forma correcta, favor de contactar al administrador !", showModal: true, })

                    }
                }

            }).catch(error => {
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Atención !', mensajeError: "Ocurrio un error en el servicio de Actualización de estatus !", showModal: true, })
            })
    }




    render() {

        return (
            <>
                {
                    this.state.idSolicitud == null ?
                        <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
                            <div className="content-wrapper">
                                <div className="content-inner" style={{ margin: '10px' }}>
                                    <br></br>

                                    {/* solCap  solAnalizada   solDev  yarechazada*/}



                                    <Row style={{ align: "center", zIndex: "2" }}>
                                        <Col lg={12} md={12} xs={12}>
                                            <Paper sx={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '100%' }} style={{ borderColor: 'white' }}>
                                                <TableContainer style={{ borderColor: 'white' }} sx={{ maxHeight: 660 }}>
                                                    <Table style={{ borderColor: 'white' }} stickyHeader aria-label="sticky table">
                                                        <TableHead style={{ borderColor: 'white' }}>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 1, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={capSol1} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 1 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 1 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes1}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 2, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={analizada2} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 2 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 2 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes2}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>
                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }} onClick={(e) => {
                                                                        this.setState({ idEstatus: 3, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                        setTimeout(() => { this.consultar() }, 500)
                                                                    }}>
                                                                        <Card.Img variant="top" src={solDev3} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 3 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 3 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes3}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 4, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={yarechazada4} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 4 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 4 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes4}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 5, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={solAutorizada5} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 5 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 5 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes5}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}
                                                                    onClick={(e) => {
                                                                        this.setState({ idEstatus: 6, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                        setTimeout(() => { this.consultar() }, 500)
                                                                    }}
                                                                >
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}>
                                                                        <Card.Img variant="top" src={clienteDinero6} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 6 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 6 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes6}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>





                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }} onClick={(e) => {
                                                                        this.setState({ idEstatus: 7, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                        setTimeout(() => { this.consultar() }, 500)
                                                                    }}>
                                                                        <Card.Img variant="top" src={comisiones7} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 7 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 7 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes7}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 8, valorBusquedaTabla: '' ,valorBusquedaFolio:""})
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={devuelta8} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 8 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 8 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes8}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>
                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 9, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={denegada9} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 9 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 9 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes9}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                                <TableCell align="center" colSpan={2}>
                                                                    <Card style={{ width: '5rem', borderColor: "white" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ idEstatus: 10, valorBusquedaTabla: '',valorBusquedaFolio:"" })
                                                                            setTimeout(() => { this.consultar() }, 500)
                                                                        }}
                                                                    >
                                                                        <Card.Img variant="top" src={vencida10} />
                                                                        <Card.Body>
                                                                            <Card.Title></Card.Title>
                                                                            <Card.Text style={{ color: this.state.idEstatus == 10 ? this.state.colorEntrando : 'black', fontSize: '25px', fontWeight: this.state.idEstatus == 10 ? "bold" : "" }}>
                                                                                {this.state.idEstatusSolicitudes10}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </TableCell>

                                                            </TableRow>

                                                        </TableHead>
                                                        <TableBody>{/* Puedes agregar filas de datos aquí si es necesario */}</TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Col>
                                    </Row>

                                    <br />
                                    <br />




                                    <Row style={{ position: "relative", zIndex: "3" }}>


                                        <Col lg={2} md={6} xs={6}>
                                            <Form.Group controlId="">

                                                <Form.Label>Desde: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon>
                                                <DatePicker style={{ width: '100%' }}
                                                    onChange={(e) => {
                                                        this.setState({ fechaInicio: e })
                                                        setTimeout(() => {
                                                            this.consultar()
                                                            this.llamarAContabilidad()
                                                        }, 500)
                                                    }}
                                                    selected={this.state.fechaInicio}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Form.Label>Hasta: <span style={{ color: 'red' }}>*</span></Form.Label>
                                                <CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon>

                                                <DatePicker
                                                    onChange={(e) => {
                                                        this.setState({ fechaFin: e })
                                                        setTimeout(() => {
                                                            this.consultar()
                                                            this.llamarAContabilidad()
                                                        }, 500)
                                                    }}
                                                    selected={this.state.fechaFin}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </Form.Group>
                                        </Col>


                                        <Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Button variant="primary" style={{ width: '100%' }} onClick={(e) => {
                                                    this.setState({idEstatusBusqueda:0, selectEstatusBusqueda:[],valorBusquedaTabla: "",valorBusquedaFolio:"" })
                                                    setTimeout(() => {
                                                        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_estatus/0", "selectEstatusBusqueda")
                                                        this.consultar()
                                                        this.llamarAContabilidad()
                                                    }, 500)
                                                }} > <UpdateIcon />Limpiar filtros  </Button>
                                            </Form.Group>
                                        </Col>

                                        {/*<Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Button variant="secondary" style={{ width: '100%' }} onClick={(e) => {
                                                    this.setState({ idEstatus: 0 })
                                                    setTimeout(() => {
                                                        this.consultar()
                                                        this.llamarAContabilidad()
                                                    }, 500)
                                                }} > <BackupTableIcon />Ver todas  </Button>
                                            </Form.Group>
                                        </Col>*/}

                                    </Row>

                                    <br></br>

                                    <Row style={{ position: "relative" }}>

                                        {
                                            /*
                                            
                                            <Col lg={2} md={6} xs={6} >
                                                <Form.Group controlId="">
                                                <Form.Label> &nbsp;</Form.Label>
                                                <Form.Control type="text" placeholder="Busqueda ..." value={this.state.valorBusquedaTabla} onChange={this.onChangeBusquedaTabla} name="busquedaTabla" />
                                                </Form.Group>
                                            </Col>
                                            
                                            */
                                        }

                                        <Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Form.Label>&nbsp;</Form.Label>
                                                {this.state.selectEstatusBusqueda}
                                            </Form.Group>
                                        </Col>


                                        <Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Form.Label> &nbsp;</Form.Label>
                                                <Form.Control type="text" placeholder={"Datos de busqueda"} value={this.state.valorBusquedaFolio} onChange={(e) => { this.setState({ valorBusquedaFolio: e.target.value }) }} name="busquedaTabla" />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={2} md={6} xs={6} >
                                            <Form.Group controlId="">
                                                <Form.Label> &nbsp;</Form.Label>
                                                <Button variant="primary" style={{ width: '100%' }} onClick={(e) => {
                                                    this.llamarBusquedaFolioDirecto()
                                                }} > <SearchOutlinedIcon />Buscar</Button>
                                            </Form.Group>
                                        </Col>




                                    </Row>

                                    <br />


                                    {/*   <Card.Img variant="top" src={cir1} style={{ width: "20%", height: "40%" }} */}

                                    <Row style={{ position: "relative", zIndex: "1" }}>

                                        <Col lg={12} md={12} xs={12}>
                                            <Paper sx={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '100%' }} >

                                                <TableContainer sx={{ maxHeight: 440 }}>
                                                    
                                                
                                                    <Table stickyHeader aria-label="sticky table">
                                                    <Col lg={12} md={12} xs={12}>
                                                        <Form.Group controlId="">
                                                            <Form.Label> &nbsp;</Form.Label>
                                                            <Form.Control type="text" placeholder="Busqueda ..." value={this.state.valorBusquedaTabla} onChange={this.onChangeBusquedaTabla} name="busquedaTabla" />
                                                        </Form.Group>
                                                    </Col>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left" colSpan={2}>
                                                                    &nbsp;
                                                                </TableCell>
                                                                <TableCell align="left" colSpan={3}>
                                                                    &nbsp;
                                                                </TableCell>
                                                                <TableCell align="left" colSpan={3}>
                                                                    &nbsp;
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                {this.state.columns.map((column, index) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={index == 0 ? "center" : "left"}
                                                                        style={{ top: 57, minWidth: column.minWidth, fontStyle: "bold", fontSize: "15px" }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.selectTR}


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                                                    <Col lg={8} md={8} xs={0}>&nbsp;</Col>
                                                    <Col lg={4} md={4} xs={12}>{this.state.paginacion}</Col>
                                                </Row>

                                            </Paper>
                                        </Col>
                                    </Row>




                                    {
                                        /*
                                        
                                                            <Row lg={12} md={12} xs={12} style={{ margin: '1px' ,overflowX: "scroll"}}>
                                                                <Table responsive="sm" bordered>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Estatus de mi venta</th>
                                                                            <th>Solicitud</th>
                                                                            <th>Sucursal</th>
                                                                            <th>Cliente</th>
                                                                            <th>Datos del crédito</th>
                                                                            <th>Opciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.selectTR}
                                                                    </tbody>
                                                                </Table>
                                                            </Row>
                                        
                                        
                                        
                                                            <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                                                                <Col lg={9} md={9} xs={9}>&nbsp;</Col>
                                        
                                                                <Col lg={3} md={3} xs={3}>{this.state.paginacion}</Col>
                                                            </Row>*/
                                    }

                                </div>
                            </div>
                        </div>
                        : <CreaNuevaSolicitud urlINE={this.props.urlINE} idPuesto={this.props.idPuesto} banderaSolicitud={this.state.banderaSolicitud} banderaDocumentos={this.state.banderaDocumentos} regresarABandeja={this.regresarABandeja} idUsuario={this.props.idUsuario} idSolicitud={this.state.idSolicitud} idSucursal={this.props.idSucursal} esMesaControl={this.props.esMesaControl} />
                }

                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showModalActualizar} onHide={() => { this.setState({ showModalActualizar: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalActualizar: false }) }}>
                            Cerrar
                        </Button>

                        {
                            this.state.idEstatusCambio != null && this.state.idEstatusCambio != "Seleccione..." && this.state.idEstatusCambio != 0
                                ?
                                <Button variant="primary" onClick={() => {
                                    this.actualizarEstatus(this.state.appIDSeleccionado, this.state.idEstatusCambio, this.state.idSolResp)
                                    this.setState({ showModalActualizar: false })
                                }}>
                                    Aceptar
                                </Button>
                                : ''
                        }


                    </Modal.Footer>
                </Modal>

            </>
        )
    }


}