import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import conexiones from '../urls/conexiones.json'
import Modal from 'react-bootstrap/Modal';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import DatePicker from "react-datepicker";
import Table from 'react-bootstrap/Table';
import botonAvanzar from '../imagenes/avanzar_boton.png'
import botonRegresar from '../imagenes/Boton_regresar.png'
import botonActualizar from '../imagenes/botonActulizar.png'
import botonGuardarSolicitud from "../imagenes/botonGuardarSolicitud.png"

import LinearProgress from '@mui/material/CircularProgress';

export default class DatosSolicitante extends Component {

  constructor() {
    super();
    this.getSolicitud = this.getSolicitud.bind(this)
    this.getCatalogo = this.getCatalogo.bind(this)
    this.armaSelect = this.armaSelect.bind(this)
    this.creaDatosSolicitante = this.creaDatosSolicitante.bind(this)
    this.getDatosPersonales = this.getDatosPersonales.bind(this)
    this.actualizaDatosPersonales = this.actualizaDatosPersonales.bind(this)
    this.getDatosDomicilio = this.getDatosDomicilio.bind(this)
    this.creaDatosDomiciliio = this.creaDatosDomiciliio.bind(this)
    this.actualizaDatosDomicilio = this.actualizaDatosDomicilio.bind(this)
    this.creaDatosContacto = this.creaDatosContacto.bind(this)
    this.getDatosContacto = this.getDatosContacto.bind(this)
    this.actualozaDatosContacto = this.actualozaDatosContacto.bind(this)
    this.creaDatosBancarios = this.creaDatosBancarios.bind(this)
    this.getDatosBancarios = this.getDatosBancarios.bind(this)
    this.actualizaDatosBancarios = this.actualizaDatosBancarios.bind(this)
    this.getDatosCP = this.getDatosCP.bind(this)
    this.formatoLog = this.formatoLog.bind(this)
    this.quitaEspecialesYNumeros = this.quitaEspecialesYNumeros.bind(this)
    this.determinaEntidad = this.determinaEntidad.bind(this)
    this.quitaEspeciales = this.quitaEspeciales.bind(this)
    this.onChangeValuesNombres = this.onChangeValuesNombres.bind(this)
    this.quitarEspacioAlFinal = this.quitarEspacioAlFinal.bind(this)
    this.avanzaSiguiente = this.avanzaSiguiente.bind(this)
    this.state = {
      idDXN:"",
      showModalLoader: false,
      muestraOtraColonia: false,
      idDatosPersonales: null,
      idDatosDomiciliares: null,
      idDatosContacto: null,
      idDatoBancarios: null,
      expresionEmail: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
      colorModalDocumentos: '#FF6B00',
      selectGenero: [],
      selectEdoCivil: [],
      selectPais: [],
      selectEntidadNacimiento: [],
      selectNacionalidad: [],
      selectIdentificacion: [],
      selectTipoVivienda: [],
      selectEntidadDomicilio: [],
      selectMunicipio: [],
      selectCiudad: [],
      selectColonia: [],
      selectMedio: [],
      colorErrorModal: '#F83C3C',
      colorSuccesModal: '#27F45E',
      colorModal: '',
      tituloModal: '',
      mensajeError: '',
      showModal: false,
      municipioDesdeCP: null,
      nuevaSolicitud: {},
      banderaDatosPerosnales: false,
      banderaDatosDomiclio: false,
      banderaDatosContacto: false,
      banderaDatosBancarios: false,
      validaciones: {
        validaPrimerNombre: false,
        validaPrimerApellido: false,
        validaSegundoApellido: false,
        validaGenero: false,
        validaEdoCivil: false,
        validaFecNacimiento: false,
        validaPaisNaci: false,
        validaEntidadNaci: false,
        validaNacionalidad: false,
        validaIdentificacion: false,
        validaCP: false,
        validaTipoVivienda: false,
        validaEntidadDomicilio: false,
        validaMunicipio: false,
        validaCiudad: false,
        validaColonia: false,
        validaCalle: false,
        validaNumExterior: false,
        validaEmail: false,
        validaNumTelefono: false,
        validaMedio: false,
        validaClabeTarjeta: false,
        validaBancaria: false,
        validaOtraColonia: false
      },
      datosSolicitante: {
        primerNombre: null,
        segundoNombre: null,
        primerApellido: null,
        segundoApellido: null,
        genero: null,
        edoCivil: null,
        fecNacimiento: null,
        paisNaci: null,
        entidadNaci: null,
        nacionalidad: null,
        identificacion: "1",
        codigoPostal: null,
        tipoVivienda: null,
        entidad: null,
        municipio: null,
        ciudad: null,
        colonia: null,
        calle: null,
        exterior: null,
        interiror: null,
        email: null,
        numeroTelefono: null,
        medio: "1",
        clabeTarjeta: null,
        bancaria: null,
        otraColonia: null
      }


    }
  }



  obtenerFechaNacimiento(curp) {
    // Validar que el CURP tiene la longitud adecuada
    if (curp.length !== 18) {
      return "CURP no válida";
    }

    // Extraer los componentes del CURP
    const anio = curp.substr(4, 2);
    const mes = curp.substr(6, 2);
    const dia = curp.substr(8, 2);

    // Determinar el siglo en base al primer carácter del CURP
    let siglo = "19"; // Por defecto, asumimos que la persona nació en el siglo 20
    const primerCaracter = curp.charAt(16).toUpperCase();
    console.log(curp[16])
    if (primerCaracter >= "A" && primerCaracter <= "J") {
      siglo = "20"; // Si el primer carácter está entre A y M, nació en el siglo 21
    }

    // Construir la fecha de nacimiento
    const fechaNacimiento = (siglo + anio) + "-" + (mes) + "-" + dia;

    return fechaNacimiento;

  }


  obtenerGenero(curp) {
    // Validar que el CURP tiene la longitud adecuada
    if (curp.length !== 18) {
      return "CURP no válida";
    }

    // Extraer los componentes del CURP


    // Construir la fecha de nacimiento
    const fechaNacimiento = curp.charAt(10);

    return fechaNacimiento;

  }


  obtenerNacionalidad(curp) {
    // Validar que el CURP tiene la longitud adecuada
    if (curp.length !== 18) {
      return "CURP no válida";
    }

    // Extraer los componentes del CURP


    // Construir la fecha de nacimiento
    const fechaNacimiento = curp.charAt(11) + "" + curp.charAt(12);

    return fechaNacimiento;

  }

  seleccionaCodigoCorrecto(coloniaSolicitante, capturas) {
    let salida = null
    for (var i = 0; i < capturas.length; i++) {
      if (coloniaSolicitante == capturas[i].colonia_id) {
        salida = capturas[i]
        break;
      }
    }
    return salida
  }
  async getDatosCP(codigoPostal) {
    try {
      const response = await fetch(conexiones.urlCP + codigoPostal);
      if (response.ok) {
        const datos = await response.json();
        console.log(datos)
        if (datos.length > 0) {
          let capturas = []
          for (var i = 0; i < datos.length; i++) {
            if (datos[i].cp == codigoPostal) {
              capturas.push(datos[i])
            }
          }
          if (capturas.length > 0) {
            let entidad = capturas[0].estado_id
            let nombreCiudad = capturas[0].estado
            let municipio = capturas[0].municipio_id
            let datosSolicitante = this.state.datosSolicitante
            datosSolicitante.entidad = entidad
            datosSolicitante.municipio = municipio
            datosSolicitante.ciudad = nombreCiudad
            console.log("las colonias   ", capturas)
            let selectColonia = []
            let options = []
            //       options.push(<option>Seleccione...</option>)     
            let contt = 0;
            let seleccionado = this.seleccionaCodigoCorrecto(datosSolicitante.colonia, capturas)
            if (/*datosSolicitante.colonia == capturas[i].colonia_id*/seleccionado != null) {
              datosSolicitante.colonia = seleccionado.colonia_id
              options.push(<option selected value={seleccionado.colonia_id + "_" + seleccionado.cp}>{seleccionado.colonia}</option>)
            }
            for (var i = 0; i < capturas.length; i++) {
              console.log("las colonias  ******************", datosSolicitante.colonia, capturas[i].colonia_id)
              if (seleccionado != null) {
                if (capturas[i].colonia_id != seleccionado.colonia_id) {
                  options.push(<option value={capturas[i].colonia_id + "_" + capturas[i].cp}>{capturas[i].colonia}</option>)
                }
              } else {
                if (contt == 0) {
                  if (datosSolicitante.colonia != 320581518) { datosSolicitante.colonia = capturas[i].colonia_id }
                  contt++
                }
                options.push(<option value={capturas[i].colonia_id + "_" + capturas[i].cp}>{capturas[i].colonia}</option>)
              }

            }

            if (datosSolicitante.colonia == 320581518) {
              this.setState({ muestraOtraColonia: true })
              options.push(<option selected value={320581518}>{"Otra colonia"}</option>)
            } else {
              options.push(<option value={320581518}>{"Otra colonia"}</option>)

            }

            let validaciones = this.state.validaciones
            validaciones.validaEntidadDomicilio = false
            validaciones.validaMunicipio = false
            validaciones.validaColonia = false

            selectColonia.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={"selectColonia"}  >{options}</Form.Select>)
            this.setState({ validaciones: validaciones, selectEntidadDomicilio: [], datosSolicitante: datosSolicitante, selectColonia: selectColonia, municipioDesdeCP: municipio })

            setTimeout(() => {

              this.getCatalogo(conexiones.urlPytonParametros + "catalogo_entidad/0", "selectEntidadDomicilio")
              this.getCatalogo(conexiones.urlPytonParametros + "catalogo_municipio/" + entidad, "selectMunicipio")
            }, 500);

          }
        } else {
          console.log("estado en este momento ", this.state)
          let datosSolicitante = this.state.datosSolicitante
          this.getCatalogo(conexiones.urlPytonParametros + "catalogo_entidad/0", "selectEntidadDomicilio")
          this.getCatalogo(conexiones.urlPytonParametros + "catalogo_municipio/" + datosSolicitante.entidad, "selectMunicipio")
          /* let validaciones = this.state.validaciones
           validaciones.validaEntidadDomicilio = true
           validaciones.validaMunicipio = true
           validaciones.validaColonia = true
           this.state.datosSolicitante.entidad = null
 
           this.setState({ validaciones: validaciones, colorModal: this.state.colorSuccesModal, tituloModal: 'AVISO!', mensajeError: "No se ha encontrado información de relacionada a ese codigo postal", showModal: true })
         */
        }



      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: response.status, showModal: true })

      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })
    }
  }

  onChangeValues = e => {
    console.log(e.target.name, e.target.value)
    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    /* if (e.target.name == "primerNombre") {
       let primerNombre = e.target.value;
       if (primerNombre == null || primerNombre == "") {
         validaciones.validaPrimerNombre = true
         this.setState({ validaciones: validaciones })
       } else {
         validaciones.validaPrimerNombre = false
         this.setState({ validaciones: validaciones })
       }
       datosSolicitante["primerNombre"] =this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
     }
 
     if (e.target.name == "segundoNombre") {
       datosSolicitante["segundoNombre"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
     }
 
     if (e.target.name == "primerApellido") {
       let primerApellido = e.target.value;
       if (primerApellido == null || primerApellido == "") {
         validaciones.validaPrimerApellido = true
         this.setState({ validaciones: validaciones })
       } else {
         validaciones.validaPrimerApellido = false
         this.setState({ validaciones: validaciones })
       }
       datosSolicitante["primerApellido"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
     }
 
 
     if (e.target.name == "segundoApellido") {
       let segundoApellido = e.target.value;
       if (segundoApellido == null || segundoApellido == "") {
         validaciones.validaSegundoApellido = true
         this.setState({ validaciones: validaciones })
       } else {
         validaciones.validaSegundoApellido = false
         this.setState({ validaciones: validaciones })
       }
       datosSolicitante["segundoApellido"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
     }*/

    if (e.target.name == "selectGenero") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaGenero = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaGenero = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["genero"] = e.target.value
    }

    if (e.target.name == "selectEdoCivil") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaEdoCivil = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEdoCivil = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["edoCivil"] = e.target.value
    }

    if (e.target.name == "fecNacimiento") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaFecNacimiento = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaFecNacimiento = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["fecNacimiento"] = e.target.value
    }

    if (e.target.name == "selectPais") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaPaisNaci = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaPaisNaci = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["paisNaci"] = e.target.value
    }

    if (e.target.name == "selectEntidadNacimiento") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaEntidadNaci = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEntidadNaci = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["entidadNaci"] = e.target.value
    }

    if (e.target.name == "selectNacionalidad") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaNacionalidad = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaNacionalidad = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["nacionalidad"] = e.target.value
    }

    if (e.target.name == "selectIdentificacion") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaIdentificacion = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaIdentificacion = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["identificacion"] = e.target.value
    }

    if (e.target.name == "codigoPostal") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaCP = true
        this.setState({ validaciones: validaciones })
      } else {
        let longitud = fechaIngreso + "";
        if (longitud.length < 5) {
          validaciones.validaCP = true
          this.setState({ validaciones: validaciones })
        } else {
          if (longitud.length <= 5) {
            validaciones.validaCP = false
            this.getDatosCP(longitud)
            this.setState({ validaciones: validaciones })

          }
        }
      }
      let longitud = fechaIngreso + "";
      if (longitud.length <= 5) {
        datosSolicitante["codigoPostal"] = e.target.value
      }

    }

    if (e.target.name == "selectTipoVivienda") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaTipoVivienda = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaTipoVivienda = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["tipoVivienda"] = e.target.value
    }

    if (e.target.name == "selectEntidadDomicilio") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaEntidadDomicilio = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEntidadDomicilio = false
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_municipio/" + e.target.value, "selectMunicipio")
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["entidad"] = e.target.value
    }

    if (e.target.name == "selectMunicipio") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaMunicipio = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaMunicipio = false
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_colonia/" + e.target.value, "selectColonia")
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["municipio"] = e.target.value
    }


    if (e.target.name == "ciudad") {
      let sector = e.target.value;
      if (sector == null || sector == "") {
        validaciones.validaCiudad = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaCiudad = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["ciudad"] = e.target.value
    }

    if (e.target.name == "otraColonia") {
      let sector = e.target.value;
      if (sector == null || sector == "") {
        validaciones.validaCiudad = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaCiudad = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["otraColonia"] = e.target.value
    }

    if (e.target.name == "selectColonia") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaColonia = true
        this.setState({ muestraOtraColonia: false })
        this.setState({ validaciones: validaciones })
      } else {

        validaciones.validaColonia = false
        this.setState({ validaciones: validaciones })
      }

      console.log("la colonia es :", sector)

      if (sector != null || sector != "Seleccione...") {
        if (sector != 320581518) {
          this.setState({ muestraOtraColonia: false })
          let idCol = e.target.value.split("_")[0]
          let cpDom = e.target.value.split("_")[1]
          datosSolicitante["codigoPostal"] = cpDom
          validaciones.validaCP = false
          this.setState({ validaciones: validaciones })
          datosSolicitante["colonia"] = idCol
        } else {
          datosSolicitante["colonia"] = e.target.value
          this.setState({ muestraOtraColonia: true })
        }
      }


    }

    if (e.target.name == "calle") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaCalle = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaCalle = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["calle"] = e.target.value
    }

    if (e.target.name == "exterior") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaNumExterior = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaNumExterior = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["exterior"] = e.target.value
    }

    if (e.target.name == "interiror") {
      datosSolicitante["interiror"] = e.target.value
    }

    if (e.target.name == "numeroTelefono") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaNumTelefono = false
        this.setState({ validaciones: validaciones })
      } else {
        let longitud = fechaIngreso + "";
        if (longitud.length < 10) {
          validaciones.validaNumTelefono = true
          this.setState({ validaciones: validaciones })
        } else {
          validaciones.validaNumTelefono = false
          this.setState({ validaciones: validaciones })
        }
      }
      let longitud = fechaIngreso + "";
      if (longitud.length <= 10) {
        datosSolicitante["numeroTelefono"] = this.quitaEspeciales(e.target.value)
      }
    }
    if (e.target.name == "email") {
      if (this.state.expresionEmail.test(e.target.value)) {
        validaciones.validaEmail = false
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEmail = true
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["email"] = e.target.value
    }


    if (e.target.name == "selectMedio") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaMedio = true
        datosSolicitante["clabeTarjeta"] = ""
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaMedio = false
        datosSolicitante["clabeTarjeta"] = ""
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["medio"] = e.target.value
    }



    if (e.target.name == "clabeTarjeta") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaClabeTarjeta = true
        this.setState({ validaciones: validaciones })
      } else {
        let longitud = fechaIngreso + "";
        if (longitud.length == 1 || longitud.length == 2 || longitud.length == 3) {
          this.getNombreBanco(longitud)
        }

        if (longitud.length < 18) {
          validaciones.validaClabeTarjeta = true
          this.setState({ validaciones: validaciones })
        } else {
          validaciones.validaClabeTarjeta = false
          this.setState({ validaciones: validaciones })
        }
      }

      let longitud = fechaIngreso + "";
      if (longitud.length <= 18) {
        datosSolicitante["clabeTarjeta"] = this.quitaEspeciales(e.target.value)
      }


    }

    if (e.target.name == "bancaria") {
      let fechaIngreso = e.target.value;
      if (fechaIngreso == null || fechaIngreso == "") {
        validaciones.validaBancaria = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaBancaria = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["bancaria"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
    }
    console.log(datosSolicitante)
    this.setState({ datosSolicitante: datosSolicitante })

  }


  onChangeValuesNombres = e => {
    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante


    if (e.target.name == "primerNombre") {
      let primerNombre = e.target.value;
      if (primerNombre == null || primerNombre == "") {
        validaciones.validaPrimerNombre = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaPrimerNombre = false
        this.setState({ validaciones: validaciones })
      }
      let salida = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
      if (this.validarString(salida)) {
        console.log("salida ", salida)
        datosSolicitante["primerNombre"] = salida
      }

    }

    if (e.target.name == "segundoNombre") {
      datosSolicitante["segundoNombre"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
    }

    if (e.target.name == "primerApellido") {
      let primerApellido = e.target.value;
      if (primerApellido == null || primerApellido == "") {
        validaciones.validaPrimerApellido = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaPrimerApellido = false
        validaciones.validaSegundoApellido = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["primerApellido"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
    }


    if (e.target.name == "segundoApellido") {
      let segundoApellido = e.target.value;
      if (segundoApellido == null || segundoApellido == "") {
        if (datosSolicitante["primerApellido"] != null) {
          if (datosSolicitante["primerApellido"].length > 0) {
            validaciones.validaSegundoApellido = false
          } else {
            validaciones.validaSegundoApellido = true
          }
        } else {
          validaciones.validaSegundoApellido = true
        }

        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaSegundoApellido = false
        this.setState({ validaciones: validaciones })
      }
      datosSolicitante["segundoApellido"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
    }


    this.setState({/*validaciones:validaciones,*/datosSolicitante: datosSolicitante })
  }

  validarString(inputString) {
    // Verificar si el string inicia con un espacio
    if (inputString.startsWith(' ')) {
      return false;
    }

    // Verificar si el string contiene doble espacio
    if (inputString.includes('  ')) {
      return false;
    }

    // Si no se encontraron problemas, el string es válido
    return true;
  }

  quitaEspeciales(valor) {
    let finalString = valor.replace(/[^0-9]/g, '')
    return finalString
  }
  quitaEspecialesYNumeros(valor) {
    let finalString = valor.replace(/[^0-9a-zñÑ ]/gi, '')
    //console.log("Final String sin especiales: "+ finalString)

    let digitsOnlyString = finalString.replace(/[^A-Za-zñÑ ]/gi, '');
    //  console.log("STRING WITHOUT NON_NUMERIC CHARACTERS -> " + digitsOnlyString)

    return digitsOnlyString
  }

  async getSolicitud(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respojsjahsahsuhuiah  ", json)
        if (json.length > 0) {
          this.setState({idBDSolicitud:json[0].id, idDXN:json[0].appid})
          this.setState({ nuevaSolicitud: json[0] })
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  async getNombreBanco(dosDigitos) {
    try {
      const response = await fetch(conexiones.urlPytonParametros + "catalogo_bancos/" + dosDigitos);
      if (response.ok) {
        const json = await response.json();
        console.log("rescatalogo_bancos  ", json)
        if (json.length > 0) {
          let datosSolicitante = this.state.datosSolicitante
          datosSolicitante["bancaria"] = json[0].banco
          this.setState({ datosSolicitante: datosSolicitante })

        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  UNSAFE_componentWillMount() {


    console.log("la id es null pkokoko ", this.props)

    if (this.props.idSolicitud != null) {
      this.getSolicitud(this.props.idSolicitud)
      this.setState({ datosSolicitante: this.props.datosSolicitante })
      if (this.props.existenDatosPersonales) {
        this.getDatosPersonales(this.props.idSolicitud)
      } if (this.props.existenDatosDomicilio) {
        this.getDatosDomicilio(this.props.idSolicitud)
      } if (this.props.existenDatosContacto) {
        this.getDatosContacto(this.props.idSolicitud)
      }
      if (this.props.existenDatosBancarios) {
        this.getDatosBancarios(this.props.idSolicitud)
      }
    } else {
      this.setState({ datosSolicitante: this.props.datosSolicitante })
    }



    setTimeout(() => {

      if (this.props.idSolicitud == null) {
        if (this.props.nuevaSolicitud.curp != undefined && this.props.nuevaSolicitud.curp != null && this.props.nuevaSolicitud.curp.length == 18) {
          console.log("el curp  ", this.props.nuevaSolicitud.curp, "   ", this.obtenerNacionalidad(this.props.nuevaSolicitud.curp))

          this.validaEntidadMexicana(conexiones.urlPytonParametros + "catalogo_entidad_nacimiento/0", this.obtenerNacionalidad(this.props.nuevaSolicitud.curp))

          this.state.datosSolicitante.fecNacimiento = this.state.datosSolicitante.fecNacimiento != null ? this.state.datosSolicitante.fecNacimiento : this.obtenerFechaNacimiento(this.props.nuevaSolicitud.curp)
          if (this.obtenerGenero(this.props.nuevaSolicitud.curp) == 'H') {
            this.state.datosSolicitante.genero = "1"
          } else if (this.obtenerGenero(this.props.nuevaSolicitud.curp) == 'M') {
            this.state.datosSolicitante.genero = "2"
          } else {
            // this.state.datosSolicitante.genero = "3"
          }
        }
      } else {
        console.log("el curp  ", this.state.nuevaSolicitud)
        if (this.state.nuevaSolicitud.curp != undefined && this.state.nuevaSolicitud.curp != null && this.state.nuevaSolicitud.curp.length == 18 && this.state.idDatosPersonales == null) {


          this.validaEntidadMexicana(conexiones.urlPytonParametros + "catalogo_entidad_nacimiento/0", this.obtenerNacionalidad(this.state.nuevaSolicitud.curp))

          this.state.datosSolicitante.fecNacimiento = this.state.datosSolicitante.fecNacimiento != null ? this.state.datosSolicitante.fecNacimiento : this.obtenerFechaNacimiento(this.state.nuevaSolicitud.curp)
          if (this.obtenerGenero(this.state.nuevaSolicitud.curp) == 'H') {
            this.state.datosSolicitante.genero = "1"
          } else if (this.obtenerGenero(this.state.nuevaSolicitud.curp) == 'M') {
            this.state.datosSolicitante.genero = "2"
          } else {
            // this.state.datosSolicitante.genero = "3"
          }
        }
      }



      setTimeout(() => {
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_genero/0", "selectGenero")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_estado_civil/0", "selectEdoCivil")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_pais_nacimiento/0", "selectPais")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_entidad_nacimiento/0", "selectEntidadNacimiento")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_nacionalidad/0", "selectNacionalidad")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_identificacion/0", "selectIdentificacion")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_vivienda/0", "selectTipoVivienda")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_entidad/0", "selectEntidadDomicilio")
        //this.getCatalogo(conexiones.urlPytonParametros + "catalogo_municipio/0", "selectMunicipio")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_ciudad/0", "selectCiudad")
        //this.getCatalogo(conexiones.urlPytonParametros + "catalogo_colonia/0", "selectColonia")
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_medio/0", "selectMedio")
      }, 500)




    }, 1000);

    setTimeout(() => {
      console.log("*************************************** entrando a datos de cp  ", this.state.datosSolicitante.codigoPostal, this.props.idSolicitud)
      this.state.datosSolicitante.codigoPostal = this.state.datosSolicitante.codigoPostal + ""
      let cp = this.state.datosSolicitante.codigoPostal + ""
      if (cp.length == 4) {
        this.state.datosSolicitante.codigoPostal = "0" + this.state.datosSolicitante.codigoPostal
      }
      if (/*this.props.idSolicitud != null && */this.state.datosSolicitante.codigoPostal != null && this.state.datosSolicitante.codigoPostal != "" && !this.state.datosSolicitante.codigoPostal.includes("null")) {
        this.getDatosCP(this.state.datosSolicitante.codigoPostal)
      }
    }, 500);




  }


  async validaEntidadMexicana(url, selector) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        console.log(json.filas)
        let esMexicnano = false
        for (var i = 0; i < json.filas.length; i++) {
          if (json.filas[i].fila[3].value == selector) {
            console.log("netnranod  ", selector, json.filas[i].fila[3])
            this.state.datosSolicitante.entidadNaci = json.filas[i].fila[0].value
            if (json.filas[i].fila[3].value != "NE") esMexicnano = true;
            break;
            // 
          }
        }
        if (esMexicnano) {
          this.state.datosSolicitante.nacionalidad = "2"
          this.state.datosSolicitante.paisNaci = "1"
        } else {
          this.state.datosSolicitante.nacionalidad = "3"
          this.state.datosSolicitante.paisNaci = "2"
        }

      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: response.status, showModal: true })

      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en selector " + selector, showModal: true })
    }
  }


  armaSelect(filas, selector) {
    let select = []
    let options = []
    options.push(<option value={null}>Seleccione...</option>)

    /*    if(this.props.idSolicitud == null){
          for (var i = 0; i < filas.length; i++) {
            let fila = filas[i].fila
            options.push(<option value={fila[0].value}>{fila[1].value}</option>)
          }*/
    //}else{
    for (var i = 0; i < filas.length; i++) {
      let fila = filas[i].fila

      if (selector == "selectGenero") {
        console.log("entrando en el genero ", this.state.datosSolicitante.genero, fila[0].value)
        if (this.state.datosSolicitante.genero == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectEdoCivil") {
        if (this.state.datosSolicitante.edoCivil == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectPais") {
        if (this.state.datosSolicitante.paisNaci == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectEntidadNacimiento") {
        if (this.state.datosSolicitante.entidadNaci == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }


      if (selector == "selectNacionalidad") {
        if (this.state.datosSolicitante.nacionalidad == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectIdentificacion") {
        if (this.state.datosSolicitante.identificacion == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }
      /* fin datos prsonales  */

      if (selector == "selectTipoVivienda") {
        if (this.state.datosSolicitante.tipoVivienda == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectEntidadDomicilio") {
        console.log("el valor es selectEntidadDomicilio  ", this.state.datosSolicitante.entidad, fila[0].value)
        if (this.state.datosSolicitante.entidad == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }

      if (selector == "selectMunicipio") {
        if (this.state.datosSolicitante.municipio == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }


      /*if (selector == "selectCiudad") {
        if (this.state.datosSolicitante.ciudad == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }*/


      if (selector == "selectColonia") {
        if (this.state.datosSolicitante.colonia == fila[0].value) {
          options.push(<option selected value={fila[0].value + "_" + fila[4].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value + "_" + fila[4].value}>{fila[1].value}</option>)
        }
      }



      if (selector == "selectMedio") {
        console.log("armando select medio ", this.state.datosSolicitante.medio, fila[0].value)
        if (this.state.datosSolicitante.medio == fila[0].value) {
          options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value}>{fila[1].value}</option>)
        }
      }



    }
    //}

    if (selector == "selectGenero") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectEdoCivil") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectPais") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectEntidadNacimiento") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector} >{options}</Form.Select>)
    }
    if (selector == "selectNacionalidad") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectIdentificacion") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectEntidadDomicilio") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector} >{options}</Form.Select>)
    }

    if (selector == "selectMunicipio") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectCiudad") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }
    if (selector == "selectColonia") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectMedio") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }

    if (selector == "selectTipoVivienda") {
      select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
    }


    //select.push( <Form.Select >{options}</Form.Select>)
    this.setState({ [selector]: select })


  }

  async getCatalogo(url, selector) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        console.log(json)
        this.armaSelect(json.filas, selector)
      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: response.status, showModal: true })

      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en selector " + selector, showModal: true })
    }
  }


  async getDatosPersonales(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_datos_solicitante/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        if (json.length > 0) {
          console.log("respuesta de la solicitud datos personales ", json[0])
          let nuevaSolicitud = this.state.datosSolicitante
          nuevaSolicitud.genero = null
          nuevaSolicitud.primerNombre = json[0].primer_nombre
          nuevaSolicitud.segundoNombre = json[0].segundo_nombre
          nuevaSolicitud.primerApellido = json[0].primer_apellido
          nuevaSolicitud.segundoApellido = json[0].segundo_apellido

          nuevaSolicitud.genero = json[0].genero

          nuevaSolicitud.edoCivil = json[0].edocivil
          nuevaSolicitud.fecNacimiento = json[0].fecha_nacimiento
          nuevaSolicitud.paisNaci = json[0].pais_nacimiento
          nuevaSolicitud.entidadNaci = json[0].entidad_nacimiento
          nuevaSolicitud.nacionalidad = json[0].nacionalidad
          nuevaSolicitud.identificacion = json[0].identificacion

          let validaciones = this.state.validaciones;
          validaciones.validaPrimerNombre = false
          validaciones.validaPrimerApellido = false
          validaciones.validaSegundoApellido = false
          validaciones.validaGenero = false
          validaciones.validaEdoCivil = false
          validaciones.validaFecNacimiento = false
          validaciones.validaPaisNaci = false
          validaciones.validaEntidadNaci = false
          validaciones.validaNacionalidad = false
          validaciones.validaIdentificacion = false
          this.props.setDatosPersonalesServicio(json)
          this.props.setBanderaExistenDatosPersonales(true)

          this.setState({ datosSolicitante: nuevaSolicitud, validaciones: validaciones, idDatosPersonales: json[0].id })


        }


      } else {
        console.error('Error:', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Error:' + response.status + "en getDatosPersonales", showModal: true })
      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en getDatosPersonales", showModal: true })
    }
  }

  async getDatosDomicilio(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_domicilio/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          let nuevaSolicitud = this.state.datosSolicitante
          nuevaSolicitud.codigoPostal = json[0].codigo_postal
          nuevaSolicitud.calle = json[0].calle
          nuevaSolicitud.exterior = json[0].no_exterior
          nuevaSolicitud.interiror = json[0].no_interior
          nuevaSolicitud.tipoVivienda = json[0].tipo_vivienda
          nuevaSolicitud.entidad = json[0].entidad
          nuevaSolicitud.municipio = json[0].municipio
          nuevaSolicitud.ciudad = json[0].ciudad
          nuevaSolicitud.colonia = json[0].colonia
          nuevaSolicitud.otraColonia = json[0].otra_colonia
          if (json[0].colonia == 320581518) {
            this.setState({ muestraOtraColonia: true })
          }
          let validaciones = this.state.validaciones;
          validaciones.validaCP = false
          validaciones.validaTipoVivienda = false
          validaciones.validaEntidadDomicilio = false
          validaciones.validaMunicipio = false
          validaciones.validaCiudad = false
          validaciones.validaColonia = false
          validaciones.validaCalle = false
          validaciones.validaNumExterior = false

          //   console.log("respuesta de la nueva solicitud ", nuevaSolicitud)
          this.props.setDatosDomicilioServicio(json)
          this.props.setBanderaExistenDatosDomicilio(true)

          this.setState({ datosSolicitante: nuevaSolicitud, validaciones: validaciones, idDatosDomiciliares: json[0].id })
        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }

  async getDatosContacto(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_contacto/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          let nuevaSolicitud = this.state.datosSolicitante
          nuevaSolicitud.email = json[0].email
          nuevaSolicitud.numeroTelefono = json[0].celular

          let validaciones = this.state.validaciones;
          validaciones.validaEmail = false
          validaciones.validaNumTelefono = false
          this.props.setDatosContactoServicio(json)
          this.props.setBanderaExistenDatosContacto(true)

          //     console.log("respuesta de la nueva solicitud ", nuevaSolicitud)
          this.setState({ datosSolicitante: nuevaSolicitud, validaciones: validaciones, idDatosContacto: json[0].id })
        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }

  async getDatosBancarios(idSolicitud) {
    try {
      const response = await fetch(conexiones.urlServicios + "wedo_datos_bancarios/" + idSolicitud);
      if (response.ok) {
        const json = await response.json();
        console.log("respuesta de la solicitud datos personales ", json[0])
        if (json.length > 0) {
          let nuevaSolicitud = this.state.datosSolicitante
          nuevaSolicitud.medio = json[0].medio
          nuevaSolicitud.clabeTarjeta = json[0].clabe
          nuevaSolicitud.bancaria = json[0].banco

          let validaciones = this.state.validaciones;
          validaciones.validaMedio = false
          validaciones.validaClabeTarjeta = false
          validaciones.validaBancaria = false

          //    console.log("respuesta de la nueva solicitud ", nuevaSolicitud)
          this.props.setDatosBancariosServicio(json)
          this.props.setBanderaExistenDatosBancarios(true)
          this.setState({ datosSolicitante: nuevaSolicitud, validaciones: validaciones, idDatoBancarios: json[0].id })
        }

      } else {
        console.error('Error response.status :', response.status);
        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

      }
    } catch (error) {
      console.error('Error: ', error);
      this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

    }
  }

  creaDatosSolicitante(isAvanzar) {

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.primerNombre == null || datosSolicitante.primerNombre == "") {
      validaciones.validaPrimerNombre = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPrimerNombre = false;
      this.setState({ validaciones: validaciones })
    }

    /* if(datosSolicitante.primerApellido == null){
       validaciones.validaPrimerApellido=true;
       this.setState({validaciones:validaciones})       
     }else{
         validaciones.validaPrimerApellido=false;
         this.setState({validaciones:validaciones})
     }
 
     if(datosSolicitante.segundoApellido == null){
       validaciones.validaSegundoApellido=true;
       this.setState({validaciones:validaciones})       
     }else{
         validaciones.validaSegundoApellido=false;
         this.setState({validaciones:validaciones})
     }*/

    if (datosSolicitante.genero == null || datosSolicitante.genero == "Seleccione...") {
      validaciones.validaGenero = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaGenero = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.edoCivil == null || datosSolicitante.edoCivil == "Seleccione...") {
      validaciones.validaEdoCivil = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEdoCivil = false;
      this.setState({ validaciones: validaciones })
    }



    if (datosSolicitante.fecNacimiento == null) {
      validaciones.validaFecNacimiento = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaFecNacimiento = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.paisNaci == null || datosSolicitante.paisNaci == "Seleccione...") {
      validaciones.validaPaisNaci = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPaisNaci = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.entidadNaci == null || datosSolicitante.entidadNaci == "Seleccione...") {
      validaciones.validaEntidadNaci = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEntidadNaci = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.nacionalidad == null || datosSolicitante.nacionalidad == "Seleccione...") {
      validaciones.validaNacionalidad = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNacionalidad = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.identificacion == null || datosSolicitante.identificacion == "Seleccione...") {
      validaciones.validaIdentificacion = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaIdentificacion = false;
      this.setState({ validaciones: validaciones })
    }

    //   if( (this.state.datosSolicitante.primerApellido != null && this.state.datosSolicitante.primerApellido != "")  || (this.state.datosSolicitante.segundoApellido != null && this.state.datosSolicitante.segundoApellido != "") ){
    let json =
    {
      "solicitud": this.props.idSolicitud,
      "primer_nombre": this.state.datosSolicitante.primerNombre,
      "segundo_nombre": this.state.datosSolicitante.segundoNombre,
      "primer_apellido": this.state.datosSolicitante.primerApellido,
      "segundo_apellido": this.state.datosSolicitante.segundoApellido,
      "fecha_nacimiento": this.state.datosSolicitante.fecNacimiento,
      "genero": this.state.datosSolicitante.genero,
      "edocivil": this.state.datosSolicitante.edoCivil,
      "pais_nacimiento": this.state.datosSolicitante.paisNaci,
      "entidad_nacimiento": this.state.datosSolicitante.entidadNaci,
      "nacionalidad": this.state.datosSolicitante.nacionalidad,
      "identificacion": this.state.datosSolicitante.identificacion
    }
    console.log("el json ", json)
    const requestReferidoOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json)
    }
    fetch(conexiones.urlServicios + "wedo_datos_solicitante/0", requestReferidoOptions)
      .then(response => response.json())
      .then(data => {

        console.log("respuesta del servicio al crear  datos del solicitante ", data)
        if (data["id"] != undefined) {
          this.props.setDatosPersonalesServicio(data)
          this.props.setBanderaExistenDatosPersonales(true)

          this.setState({ idDatosPersonales: data["id"], banderaDatosPerosnales: true, })
          if (typeof (isAvanzar) == 'object') {
            this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos personales con exito¡", showModal: true })
          }


          // this.props.guardaIdSolicitud(data["id"])
          // this.props.clickAvanzar("datosSol");
        } else {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosPerosnales: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
        }


      }).catch(error => {
        this.setState({ colorModal: this.state.colorErrorModal, banderaDatosPerosnales: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

      })
    // }else{
    //   this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: "Se debe lllenar al menos uno de los apellidos", showModal: true })

    //}



  }


  actualizaDatosPersonales(isAvanzar) {


    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.primerNombre == null || datosSolicitante.primerNombre == "") {
      validaciones.validaPrimerNombre = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPrimerNombre = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.primerApellido == null || datosSolicitante.primerApellido == "") {
      validaciones.validaPrimerApellido = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPrimerApellido = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.segundoApellido == null || datosSolicitante.segundoApellido == "") {
      validaciones.validaSegundoApellido = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaSegundoApellido = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.genero == null || datosSolicitante.genero == "Seleccione...") {
      validaciones.validaGenero = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaGenero = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.edoCivil == null || datosSolicitante.edoCivil == "Seleccione...") {
      validaciones.validaEdoCivil = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEdoCivil = false;
      this.setState({ validaciones: validaciones })
    }


    if (datosSolicitante.fecNacimiento == null) {
      validaciones.validaFecNacimiento = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaFecNacimiento = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.paisNaci == null || datosSolicitante.paisNaci == "Seleccione...") {
      validaciones.validaPaisNaci = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaPaisNaci = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.entidadNaci == null || datosSolicitante.entidadNaci == "Seleccione...") {
      validaciones.validaEntidadNaci = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEntidadNaci = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.nacionalidad == null || datosSolicitante.nacionalidad == "Seleccione...") {
      validaciones.validaNacionalidad = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNacionalidad = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.identificacion == null || datosSolicitante.identificacion == "Seleccione...") {
      validaciones.validaIdentificacion = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaIdentificacion = false;
      this.setState({ validaciones: validaciones })
    }

    if ((this.state.datosSolicitante.primerApellido != null && this.state.datosSolicitante.primerApellido != "") || (this.state.datosSolicitante.segundoApellido != null && this.state.datosSolicitante.segundoApellido != "")) {
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "primer_nombre": this.state.datosSolicitante.primerNombre,
        "segundo_nombre": this.state.datosSolicitante.segundoNombre,
        "primer_apellido": this.state.datosSolicitante.primerApellido,
        "segundo_apellido": this.state.datosSolicitante.segundoApellido,
        "fecha_nacimiento": this.state.datosSolicitante.fecNacimiento,
        "genero": this.state.datosSolicitante.genero,
        "edocivil": this.state.datosSolicitante.edoCivil,
        "pais_nacimiento": this.state.datosSolicitante.paisNaci,
        "entidad_nacimiento": this.state.datosSolicitante.entidadNaci,
        "nacionalidad": this.state.datosSolicitante.nacionalidad,
        "identificacion": this.state.datosSolicitante.identificacion
      }
      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }

      fetch(conexiones.urlServiciosActualiza + "wedo_datos_solicitante/" + this.props.idSolicitud, requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al actualizar  una solicitud ", data)
          if (data["id"] != undefined) {
            this.props.setDatosPersonalesServicio(data)
            this.props.setBanderaExistenDatosPersonales(true)
            this.setState({ idDatosPersonales: data["id"], banderaDatosPerosnales: true, })
            if (typeof (isAvanzar) == 'object') {
              this.setState({ colorModal: this.state.colorSuccesModal, banderaDatosPerosnales: true, tituloModal: '¡Exito!', mensajeError: "Se actualizaron los datos personales", showModal: true })
            }

          } else {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosPerosnales: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosPerosnales: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

        })

    } else {
      this.setState({ colorModal: this.state.colorErrorModal, banderaDatosPerosnales: false, tituloModal: '¡Atención!', mensajeError: "Se debe lllenar al menos uno de los apellidos", showModal: true })
    }



  }

  creaDatosDomiciliio(isAvanzar) {

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante
    //console.log("daots sol ",datos)
    if (datosSolicitante.codigoPostal == null || datosSolicitante.codigoPostal == "") {
      validaciones.validaCP = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCP = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.tipoVivienda == null || datosSolicitante.tipoVivienda == "Seleccione...") {
      validaciones.validaTipoVivienda = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaTipoVivienda = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.entidad == null || datosSolicitante.entidad == "Seleccione...") {
      validaciones.validaEntidadDomicilio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEntidadDomicilio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.municipio == null || datosSolicitante.municipio == "Seleccione...") {
      validaciones.validaMunicipio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMunicipio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.ciudad == null || datosSolicitante.ciudad == "") {
      validaciones.validaCiudad = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCiudad = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.colonia == null || datosSolicitante.colonia == "Seleccione...") {
      validaciones.validaColonia = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaColonia = false;
      this.setState({ validaciones: validaciones })
    }


    if (datosSolicitante.calle == null || datosSolicitante.calle == "") {
      validaciones.validaCalle = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCalle = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.exterior == null || datosSolicitante.exterior == "") {
      validaciones.validaNumExterior = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNumExterior = false;
      this.setState({ validaciones: validaciones })
    }


    if (this.state.muestraOtraColonia == true) {
      if (datosSolicitante.otraColonia == null || datosSolicitante.otraColonia == "") {
        validaciones.validaOtraColonia = true;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaOtraColonia = false;
        this.setState({ validaciones: validaciones })
      }
    }

    if (validaciones.validaCP == true || validaciones.validaTipoVivienda == true || validaciones.validaEntidadDomicilio == true || validaciones.validaMunicipio == true ||
      validaciones.validaCiudad == true || validaciones.validaColonia == true || validaciones.validaCalle == true || validaciones.validaNumExterior == true) {
    } else {
      this.setState({ validaciones: validaciones })
      if (validaciones.validaOtraColonia == true) {
        this.setState({ validaciones: validaciones })
      } else {
        let json =
        {
          "solicitud": this.props.idSolicitud,
          "codigo_postal": this.state.datosSolicitante.codigoPostal,
          "calle": this.state.datosSolicitante.calle,
          "no_exterior": this.state.datosSolicitante.exterior,
          "no_interior": this.state.datosSolicitante.interiror,
          "tipo_vivienda": this.state.datosSolicitante.tipoVivienda,
          "entidad": this.state.datosSolicitante.entidad,
          "municipio": this.state.datosSolicitante.municipio,
          "ciudad": this.state.datosSolicitante.ciudad,
          "colonia": this.state.datosSolicitante.colonia,
          "otra_colonia": this.state.datosSolicitante.otraColonia


        }
        console.log("el json ", json)
        const requestReferidoOptions = {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicios + "wedo_domicilio/0", requestReferidoOptions)
          .then(response => response.json())
          .then(data => {

            console.log("respuesta del servicio al crear  datos del domicilio  ", data)
            if (data["id"] != undefined) {
              this.props.setDatosDomicilioServicio(data)
              this.props.setBanderaExistenDatosDomicilio(true)

              this.setState({ idDatosDomiciliares: data["id"], banderaDatosDomiclio: true })
              if (typeof (isAvanzar) == 'object') {
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Éxito!', mensajeError: "Se actualizaron los datos domiciliares", showModal: true })
              }
            } else {
              this.setState({ colorModal: this.state.colorErrorModal, banderaDatosDomiclio: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
            }


          }).catch(error => {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosDomiclio: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

          })
      }
    }

  }

  actualizaDatosDomicilio(isAvanzar) {
    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.codigoPostal == null || datosSolicitante.codigoPostal == "") {
      validaciones.validaCP = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCP = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.tipoVivienda == null || datosSolicitante.tipoVivienda == "Seleccione...") {
      validaciones.validaTipoVivienda = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaTipoVivienda = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.entidad == null || datosSolicitante.entidad == "Seleccione...") {
      validaciones.validaEntidadDomicilio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaEntidadDomicilio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.municipio == null || datosSolicitante.municipio == "Seleccione...") {
      validaciones.validaMunicipio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMunicipio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.ciudad == null || datosSolicitante.ciudad == "") {
      validaciones.validaCiudad = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCiudad = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.colonia == null || datosSolicitante.colonia == "Seleccione...") {
      validaciones.validaColonia = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaColonia = false;
      this.setState({ validaciones: validaciones })
    }


    if (datosSolicitante.calle == null || datosSolicitante.calle == "") {
      validaciones.validaCalle = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaCalle = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.exterior == null || datosSolicitante.exterior == "") {
      validaciones.validaNumExterior = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNumExterior = false;
      this.setState({ validaciones: validaciones })
    }


    if (this.state.muestraOtraColonia == true) {
      if (datosSolicitante.otraColonia == null || datosSolicitante.otraColonia == "") {
        validaciones.validaOtraColonia = true;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaOtraColonia = false;
        this.setState({ validaciones: validaciones })
      }
    }


    if (validaciones.validaCP == true || validaciones.validaTipoVivienda == true || validaciones.validaEntidadDomicilio == true || validaciones.validaMunicipio == true ||
      validaciones.validaCiudad == true || validaciones.validaColonia == true || validaciones.validaCalle == true || validaciones.validaNumExterior == true) {
    } else {
      this.setState({ validaciones: validaciones })
      if (validaciones.validaOtraColonia == true) {
        this.setState({ validaciones: validaciones })
      } else {
        let json =
        {
          "solicitud": this.props.idSolicitud,
          "codigo_postal": this.state.datosSolicitante.codigoPostal,
          "calle": this.state.datosSolicitante.calle,
          "no_exterior": this.state.datosSolicitante.exterior,
          "no_interior": this.state.datosSolicitante.interiror,
          "tipo_vivienda": this.state.datosSolicitante.tipoVivienda,
          "entidad": this.state.datosSolicitante.entidad,
          "municipio": this.state.datosSolicitante.municipio,
          "ciudad": this.state.datosSolicitante.ciudad,
          "colonia": this.state.datosSolicitante.colonia,
          "otra_colonia": this.state.datosSolicitante.otraColonia

        }
        console.log("el json ", json)
        const requestReferidoOptions = {
          method: "PUT",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(json)
        }
        fetch(conexiones.urlServiciosActualiza + "wedo_domicilio/" + this.props.idSolicitud, requestReferidoOptions)
          .then(response => response.json())
          .then(data => {

            console.log("respuesta del servicio al actualizar datos del domicilio  ", data)
            if (data["id"] != undefined) {
              this.props.setDatosDomicilioServicio(data)

              this.props.setBanderaExistenDatosDomicilio(true)

              this.setState({ idDatosDomiciliares: data["id"], banderaDatosDomiclio: true })
              if (typeof (isAvanzar) == 'object') {
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Éxito!', mensajeError: "Se actualizó el domicilio", showModal: true })
              }

            } else {
              this.setState({ colorModal: this.state.colorErrorModal, banderaDatosDomiclio: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
            }


          }).catch(error => {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosDomiclio: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

          })
      }
    }
  }

  creaDatosContacto(isAvanzar) {

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.numeroTelefono == null || datosSolicitante.numeroTelefono == "") {
      validaciones.validaNumTelefono = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNumTelefono = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.email == null || datosSolicitante.email == "") {
      validaciones.validaEmail = true;
      this.setState({ validaciones: validaciones })
    } else {
      if (this.state.expresionEmail.test(datosSolicitante.email)) {
        validaciones.validaEmail = false;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEmail = true;
        this.setState({ validaciones: validaciones })
      }

    }

    if (this.state.validaciones.validaEmail == false && this.state.validaciones.validaNumTelefono == false) {
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "nombre": this.state.datosSolicitante.primerNombre + " " + this.state.datosSolicitante.primerApellido,
        "email": this.state.datosSolicitante.email,
        "celular": this.state.datosSolicitante.numeroTelefono,
        "solicitud": this.props.idSolicitud
      }

      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.urlServicios + "wedo_contacto/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al crear  datos del domicilio  ", data)
          if (data["id"] != undefined) {
            this.props.setDatosContactoServicio(data)
            this.props.setBanderaExistenDatosContacto(true)
            this.setState({ idDatosContacto: data["id"], banderaDatosContacto: true, })
            if (typeof (isAvanzar) == 'object') {
              this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Éxito!', mensajeError: "Se actualizaron los datos de contacto con exito¡", showModal: true })
            }

          } else {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosContacto: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosContacto: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

        })
    }


  }

  actualozaDatosContacto(isAvanzar) {

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.numeroTelefono == null || datosSolicitante.numeroTelefono == "") {
      validaciones.validaNumTelefono = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaNumTelefono = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.email == null || datosSolicitante.email == "") {
      validaciones.validaEmail = true;
      this.setState({ validaciones: validaciones })
    } else {
      if (this.state.expresionEmail.test(datosSolicitante.email)) {
        validaciones.validaEmail = false;
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaEmail = true;
        this.setState({ validaciones: validaciones })
      }

    }

    if (this.state.validaciones.validaEmail == false && this.state.validaciones.validaNumTelefono == false) {
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "nombre": this.state.datosSolicitante.primerNombre + " " + this.state.datosSolicitante.primerApellido,
        "email": this.state.datosSolicitante.email,
        "celular": this.state.datosSolicitante.numeroTelefono,
        "solicitud": this.props.idSolicitud
      }

      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.urlServiciosActualiza + "wedo_contacto/" + this.props.idSolicitud, requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al crear  datos del domicilio  ", data)
          if (data["id"] != undefined) {
            this.props.setDatosContactoServicio(data)
            this.props.setBanderaExistenDatosContacto(true)
            this.setState({ idDatosContacto: data["id"], banderaDatosContacto: true })
            if (typeof (isAvanzar) == 'object') {
              this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Éxito!', mensajeError: "Se actualizaron los datos de contacto", showModal: true })
            }
          } else {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosContacto: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosContacto: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

        })
    } else {
      this.setState({ colorModal: this.state.colorErrorModal, banderaDatosContacto: false, tituloModal: '¡Atención!', mensajeError: "Revisa el contenido del email o del numero de telefono", showModal: true })


    }


  }


  creaDatosBancarios(isAvanzar) {

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.medio == null || datosSolicitante.medio == "Seleccione...") {
      validaciones.validaMedio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMedio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.clabeTarjeta == null || datosSolicitante.clabeTarjeta == "") {
      validaciones.validaClabeTarjeta = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaClabeTarjeta = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.bancaria == null || datosSolicitante.bancaria == "") {
      validaciones.validaBancaria = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaBancaria = false;
      this.setState({ validaciones: validaciones })
    }
    if (this.state.validaciones.validaMedio == false && this.state.validaciones.validaClabeTarjeta == false && this.state.validaciones.validaBancaria == false) {
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "banco": this.state.datosSolicitante.bancaria,
        "clabe": this.state.datosSolicitante.clabeTarjeta,
        "medio": this.state.datosSolicitante.medio
      }

      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.urlServicios + "wedo_datos_bancarios/0", requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al crear  datos del domicilio  ", data)
          if (data["id"] != undefined) {
            this.props.setDatosBancariosServicio(data)
            this.props.setBanderaExistenDatosBancarios(true)
            this.setState({ idDatoBancarios: data["id"], banderaDatosBancarios: true })

            if (typeof (isAvanzar) == 'object') {
              this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Éxito!', mensajeError: "Se actualizaron los datos bancarios con exito¡", showModal: true })
            }

          } else {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosBancarios: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosBancarios: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

        })
    }


  }
  actualizaDatosBancarios(isAvanzar) {


    console.log(isAvanzar, typeof (isAvanzar))

    let validaciones = this.state.validaciones
    let datosSolicitante = this.state.datosSolicitante

    if (datosSolicitante.medio == null || datosSolicitante.medio == "Seleccione...") {
      validaciones.validaMedio = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaMedio = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.clabeTarjeta == null || datosSolicitante.clabeTarjeta == "") {
      validaciones.validaClabeTarjeta = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaClabeTarjeta = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.bancaria == null || datosSolicitante.bancaria == "") {
      validaciones.validaBancaria = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaBancaria = false;
      this.setState({ validaciones: validaciones })
    }

    if (datosSolicitante.bancaria == null) {
      validaciones.validaBancaria = true;
      this.setState({ validaciones: validaciones })
    } else {
      validaciones.validaBancaria = false;
      this.setState({ validaciones: validaciones })
    }

    if (this.state.validaciones.validaMedio == false && this.state.validaciones.validaClabeTarjeta == false && this.state.validaciones.validaBancaria == false) {
      let json =
      {
        "solicitud": this.props.idSolicitud,
        "banco": this.state.datosSolicitante.bancaria,
        "clabe": this.state.datosSolicitante.clabeTarjeta,
        "medio": this.state.datosSolicitante.medio
      }

      console.log("el json ", json)
      const requestReferidoOptions = {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
      }
      fetch(conexiones.urlServiciosActualiza + "wedo_datos_bancarios/" + this.props.idSolicitud, requestReferidoOptions)
        .then(response => response.json())
        .then(data => {

          console.log("respuesta del servicio al crear  datos del domicilio  ", data)
          if (data["id"] != undefined) {
            this.props.setDatosBancariosServicio(data)

            this.props.setBanderaExistenDatosBancarios(true)
            this.setState({ idDatoBancarios: data["id"], banderaDatosBancarios: true })
            if (typeof (isAvanzar) == 'object') {
              this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: '¡Exito!', mensajeError: "Se actualizaron los datos bancarios", showModal: true })
            }

          } else {
            this.setState({ colorModal: this.state.colorErrorModal, banderaDatosBancarios: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
          }


        }).catch(error => {
          this.setState({ colorModal: this.state.colorErrorModal, banderaDatosBancarios: false, tituloModal: '¡Atención!', mensajeError: error.toString(), showModal: true })

        })
    } else {
      let data = {
        "general": "Debes validar los datos bancarios",
        "medio": "debe contener un medio seleccionado",
        "clabe": "cuenta clabe a 18 posiciones",
        "tarjeta": "cuenta clabe a 16 posiciones",
        "institucion": "nombre de la institucion"
      }
      this.setState({ colorModal: this.state.colorErrorModal, banderaDatosBancarios: false, tituloModal: '¡Atención!', mensajeError: this.formatoLog(data), showModal: true })
    }



  }

  formatoLog(data) {
    let encabezados = Object.keys(data)
    let contenido = Object.values(data)
    let salida = ''

    let salidaEncabezados = []

    console.log("encabzados ", encabezados)
    for (var i = 0; i < encabezados.length; i++) {
      salidaEncabezados.push(
        <tr>
          <td>{encabezados[i]}</td>
          <td>{contenido[i]}</td>
        </tr>

      );
    }


    salida =
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {salidaEncabezados}
        </tbody>
      </Table>



    return salida
  }

  determinaEntidad(abreviatura) {
    let idEntidad = null
    if (abreviatura == "AS") {
      idEntidad = 1
    }
    if (abreviatura == "BC") {
      idEntidad = 2
    }
    if (abreviatura == "BS") {
      idEntidad = 3
    }
    if (abreviatura == "CC") {
      idEntidad = 4
    }
    if (abreviatura == "CL") {
      idEntidad = 5
    }
    if (abreviatura == "CM") {
      idEntidad = 6
    }
    if (abreviatura == "CS") {
      idEntidad = 7
    }
    if (abreviatura == "CH") {
      idEntidad = 8
    }
    if (abreviatura == "DF") {
      idEntidad = 9
    }
    if (abreviatura == "DG") {
      idEntidad = 10
    }
    if (abreviatura == "GT") {
      idEntidad = 11
    }
    if (abreviatura == "GR") {
      idEntidad = 12
    }
    if (abreviatura == "HG") {
      idEntidad = 13
    }
    if (abreviatura == "JC") {
      idEntidad = 14
    }
    if (abreviatura == "MC") {
      idEntidad = 15
    }
    if (abreviatura == "MN") {
      idEntidad = 16
    }

    if (abreviatura == "MS") {
      idEntidad = 17
    }
    if (abreviatura == "NT") {
      idEntidad = 18
    }
    if (abreviatura == "NL") {
      idEntidad = 19
    }
    if (abreviatura == "OC") {
      idEntidad = 20
    }
    if (abreviatura == "PL") {
      idEntidad = 21
    }
    if (abreviatura == "QT") {
      idEntidad = 22
    }
    if (abreviatura == "QR") {
      idEntidad = 23
    }
    if (abreviatura == "SP") {
      idEntidad = 24
    }
    if (abreviatura == "SL") {
      idEntidad = 25
    }
    if (abreviatura == "SR") {
      idEntidad = 26
    }
    if (abreviatura == "TC") {
      idEntidad = 27
    }
    if (abreviatura == "TS") {
      idEntidad = 28
    }
    if (abreviatura == "TL") {
      idEntidad = 29
    }
    if (abreviatura == "VZ") {
      idEntidad = 30
    }
    if (abreviatura == "YN") {
      idEntidad = 31
    }
    if (abreviatura == "ZS") {
      idEntidad = 32
    }

    return idEntidad
  }

  formatoLog(data) {
    let encabezados = Object.keys(data)
    let contenido = Object.values(data)
    let salida = ''

    let salidaEncabezados = []

    console.log("encabzados ", encabezados)
    for (var i = 0; i < encabezados.length; i++) {
      salidaEncabezados.push(
        <tr>
          <td>{encabezados[i]}</td>
          <td>{contenido[i]}</td>
        </tr>

      );
    }


    salida =
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {salidaEncabezados}
        </tbody>
      </Table>



    return salida
  }

  quitarEspacioAlFinal(campo, inputString) {
    if (inputString != null) {
      // Verificar si el string termina con un espacio
      if (inputString.endsWith(' ')) {
        // Si sí termina con espacio, eliminarlo y devolver el nuevo string
        inputString = inputString.substring(0, inputString.length - 1);
      }

      // Si no termina con espacio, devolver el string original
      let datosSolicitante = this.state.datosSolicitante
      datosSolicitante["" + campo + ""] = inputString
      this.setState({ datosSolicitante: datosSolicitante });
    }

  }


  avanzaSiguiente() {


    this.setState({ showModalLoader: true })
    setTimeout(() => {
      if (this.state.idDatosPersonales == null) {
        this.creaDatosSolicitante()
      } else {
        this.actualizaDatosPersonales()
      }

      if (this.state.idDatosDomiciliares == null) {
        this.creaDatosDomiciliio()
      } else {
        this.actualizaDatosDomicilio()
      }

      if (this.state.idDatosContacto == null) {
        this.creaDatosContacto()
      } else {
        this.actualozaDatosContacto()
      }

      if (this.state.idDatoBancarios == null) {
        this.creaDatosBancarios()
      } else {
        this.actualizaDatosBancarios()
      }


      setTimeout(() => {

        console.log("************************** bandera para decidir si pasamos a la otra venatana (true)*******************************")
        console.log("bandera personales ", this.state.banderaDatosPerosnales)
        console.log("bandera dokmicilio ", this.state.banderaDatosDomiclio)
        console.log("bandera contacto ", this.state.banderaDatosContacto)
        console.log("bandera bancarios ", this.state.banderaDatosBancarios)
        console.log("*******************************************************************************************************************")
        console.log("************************** ids de la secciones guardadas de asociadas a una solicitud*******************************")
        console.log("bandera personales ", this.state.idDatosDomiciliares)
        console.log("bandera dokmicilio ", this.state.idDatosDomiciliares)
        console.log("bandera contacto ", this.state.idDatosContacto)
        console.log("bandera bancarios ", this.state.idDatoBancarios)
        console.log("*******************************************************************************************************************")


        if (this.state.banderaDatosPerosnales == true && this.state.banderaDatosDomiclio == true && this.state.banderaDatosContacto == true && this.state.banderaDatosBancarios == true) {
          this.setState({ showModalLoader: false })
          this.props.clickAvanzar("ocupacion")
        } else {
          //if(!this.state.banderaDatosPerosnales){
          this.setState({ showModalLoader: false })
          //   this.setState({ colorModal: this.state.colorErrorModal,banderaDatosBancarios:false, tituloModal: '¡Atención!', mensajeError: "Hay campos vacios en Datos personales que son requeridos", showModal: true })
          //}
        }

      }, 2500);

    }, 500);



  }


  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12">
            <Row>
              <Col xs={12}>
                <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.props.idSolicitud == null ? "0":this.props.idSolicitud }  DXN:{this.state.idDXN} </span>

              </Col>
            </Row>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Datos personales</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Primer nombre: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="primerNombre" onBlur={() => { this.quitarEspacioAlFinal("primerNombre", this.state.datosSolicitante.primerNombre) }} onChange={this.onChangeValuesNombres} value={this.state.datosSolicitante.primerNombre} />
                        {this.state.validaciones.validaPrimerNombre == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Segundo nombre:</Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="segundoNombre" onBlur={() => { this.quitarEspacioAlFinal("segundoNombre", this.state.datosSolicitante.segundoNombre) }} onChange={this.onChangeValuesNombres} value={this.state.datosSolicitante.segundoNombre} />

                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Primer apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="primerApellido" onBlur={() => { this.quitarEspacioAlFinal("primerApellido", this.state.datosSolicitante.primerApellido) }} onChange={this.onChangeValuesNombres} value={this.state.datosSolicitante.primerApellido} />
                        {this.state.validaciones.validaPrimerApellido == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un apellido paterno</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Segundo apellido: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="segundoApellido" onBlur={() => { this.quitarEspacioAlFinal("segundoApellido", this.state.datosSolicitante.segundoApellido) }} onChange={this.onChangeValuesNombres} value={this.state.datosSolicitante.segundoApellido} />
                        {this.state.validaciones.validaSegundoApellido == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un apellido materno</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Género: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectGenero}
                        {this.state.validaciones.validaGenero == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un género</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Estado civil: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectEdoCivil}
                        {this.state.validaciones.validaEdoCivil == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un estado civil</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>

                  <br />
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Fecha de nacimiento: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} dateFormat="dd/mm/yyyy" type="date" placeholder="dd-mm-yyyy" name="fecNacimiento" onChange={this.onChangeValues} value={this.state.datosSolicitante.fecNacimiento} />
                        {this.state.validaciones.validaFecNacimiento == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar la fecha de nacimiento</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>País de nacimiento: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectPais}
                        {this.state.validaciones.validaPaisNaci == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un país</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Entidad de nacimiento: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectEntidadNacimiento}
                        {this.state.validaciones.validaEntidadNaci == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una entidad</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Nacionalidad: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectNacionalidad}
                        {this.state.validaciones.validaNacionalidad == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una nacionalidad</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>

                  <br />
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Identificación oficial: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectIdentificacion}
                        {this.state.validaciones.validaIdentificacion == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una identificación</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>&nbsp;</Form.Label>
                        {
                          this.state.idDatosPersonales == null ?
                            <img src={botonGuardarSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.creaDatosSolicitante}

                            /> :
                            <img src={botonActualizar} disabled={this.props.banderaSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualizaDatosPersonales}

                            />



                        }
                      </Form.Group>
                    </div>
                  </div>


                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Domicilio</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Código postal: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="number" placeholder="" name="codigoPostal" maxLength="5" onChange={this.onChangeValues} value={this.state.datosSolicitante.codigoPostal} />
                        {this.state.validaciones.validaCP == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un código postal</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    {/* <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Tipo de vivienda: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectTipoVivienda}
                        {this.state.validaciones.validaTipoVivienda == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un tipo de vivienda</span></Form.Label>
                        ) : null}
                      </Form.Group>
                      </div>*/}

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Entidad: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectEntidadDomicilio}
                        {this.state.validaciones.validaEntidadDomicilio == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar una entidad</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Municipio / Alcaldía: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectMunicipio}
                        {this.state.validaciones.validaMunicipio == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un municipio</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Ciudad: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="ciudad" onChange={this.onChangeValues} value={this.state.datosSolicitante.ciudad} />
                        {this.state.validaciones.validaCiudad == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar una ciudad</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>

                  <br />
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Colonia: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectColonia}
                        {this.state.validaciones.validaColonia == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se seleccionar ingresar una colonia</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Domicilio/calle: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="calle" onChange={this.onChangeValues} value={this.state.datosSolicitante.calle} />
                        {this.state.validaciones.validaCalle == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una calle</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-6">
                          <Form.Group className="mb-6" controlId="">
                            <Form.Label>No. exterior: <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="exterior" onChange={this.onChangeValues} value={this.state.datosSolicitante.exterior} />
                            {this.state.validaciones.validaNumExterior == true ? (
                              <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un número exterior</span></Form.Label>
                            ) : null}
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-6">
                          <Form.Group className="mb-6" controlId="">
                            <Form.Label>No. interior:</Form.Label>
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="interiror" onChange={this.onChangeValues} value={this.state.datosSolicitante.interiror} />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Tipo de vivienda: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectTipoVivienda}
                        {this.state.validaciones.validaTipoVivienda == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un tipo de vivienda</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </div>

                  {
                    this.state.muestraOtraColonia == true ?
                      <>
                        <br />
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-xs-3">
                            <Form.Group className="mb-6" controlId="">
                              <Form.Label>Otra Colonia: <span style={{ color: 'red' }}>*</span></Form.Label>
                              <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="otraColonia" onChange={this.onChangeValues} value={this.state.datosSolicitante.otraColonia} />
                              {this.state.validaciones.validaOtraColonia == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una colonia</span></Form.Label>
                              ) : null}
                            </Form.Group>
                          </div>
                        </div>
                      </> : ''
                  }

                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>&nbsp;</Form.Label>
                        {
                          this.state.idDatosDomiciliares == null ?
                            <img src={botonGuardarSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.creaDatosDomiciliio}

                            />
                            :
                            <img src={botonActualizar} disabled={this.props.banderaSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualizaDatosDomicilio}

                            />


                        }
                      </Form.Group>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Contacto</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-xs-4">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Email: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="email" placeholder="" name="email" onChange={this.onChangeValues} value={this.state.datosSolicitante.email} />
                        {this.state.validaciones.validaEmail == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un email válido</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-4 col-md-4 col-xs-4">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Celular (10 dígitos): <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} min="0" type="text" placeholder="" name="numeroTelefono" onChange={this.onChangeValues} value={this.state.datosSolicitante.numeroTelefono} />
                        {this.state.validaciones.validaNumTelefono == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar el teléfono a 10 dígitos</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-4">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>&nbsp;</Form.Label>
                        {
                          this.state.idDatosContacto == null ?
                            <img src={botonGuardarSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.creaDatosContacto}

                            />
                            :
                            <img src={botonActualizar} disabled={this.props.banderaSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualozaDatosContacto}

                            />


                        }
                      </Form.Group>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Datos Bancarios</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Medio: <span style={{ color: 'red' }}>*</span></Form.Label>
                        {this.state.selectMedio}
                        {this.state.validaciones.validaMedio == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un medio</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>
                          {
                            this.state.datosSolicitante.medio == 1 ? <div>CLABE <span style={{ color: 'red' }}>*</span></div> : ''
                          }
                          {
                            this.state.datosSolicitante.medio == 2 ? <div>Numero de tarjeta <span style={{ color: 'red' }}>*</span></div> : ''
                          }

                        </Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="clabeTarjeta" onChange={this.onChangeValues} value={this.state.datosSolicitante.clabeTarjeta} />
                        {this.state.validaciones.validaClabeTarjeta == true && this.state.datosSolicitante.medio == 1 ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar la cuenta CLABE a 18 dígitos </span></Form.Label>
                        ) : null}
                        {this.state.validaciones.validaClabeTarjeta == true && this.state.datosSolicitante.medio == 2 ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar el numero de tarjeta a 16 dígitos </span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>

                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>Nombre de la institución bancaria: <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="bancaria" onChange={this.onChangeValues} value={this.state.datosSolicitante.bancaria} />
                        {this.state.validaciones.validaBancaria == true ? (
                          <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una institución bancaria</span></Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3">
                      <Form.Group className="mb-6" controlId="">
                        <Form.Label>&nbsp;</Form.Label>
                        {
                          this.state.idDatoBancarios == null ?
                            <img src={botonGuardarSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.creaDatosBancarios}

                            />
                            :
                            <img src={botonActualizar} disabled={this.props.banderaSolicitud}
                              style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                              onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualizaDatosBancarios}

                            />


                        }
                      </Form.Group>
                    </div>


                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <br />
            <div className="row">

              <div style={{ display: "flex" }}>

                <img src={botonRegresar}
                  style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }}
                  onClick={() => { this.props.clickAvanzar("solicitud") }}
                />





                <img src={botonAvanzar} disabled={this.props.banderaSolicitud}
                  style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }}
                  onClick={() => {

                    this.props.banderaSolicitud ? console.log("mkk") : this.avanzaSiguiente()
                  }}

                />
              </div>






            </div>



          </div>
        </div>
        <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
              Cerrar
            </Button>

          </Modal.Footer>
        </Modal>


        <Modal backdrop="static"
          keyboard={false}
          show={this.state.showModalLoader} onHide={() => { this.setState({ showModalLoader: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} >
            <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{"Cargando información, por favor espera…"}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>
            {/*   <Spinner animation="grow" variant="primary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="dark" />
                       
                        */

            }

            <LinearProgress color="secondary" />



          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>

    )
  }
}